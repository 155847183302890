import { get } from "util/APICaller";
import { API_URL } from "util/constant";

const getMenuListByStudent = (accountId, params) => {
    return get(`${API_URL}/accounts/${accountId}/student/menu`, params);
};

export const menuService = {
    getMenuListByStudent,
};
