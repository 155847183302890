import { Modal, Spin } from "antd";
import InfoFieldDatePicker from "common/CusInfoField/infoFieldDatePicker/InfoFieldDatePicker";
import InfoFieldSelect from "common/CusInfoField/infoFieldSelect/InfoFieldSelect";
// import { Notification } from "common/Notification";
import moment from "moment";
import { useAttendanceConext } from "page/diemDanh/Context";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { attendanceService } from "services/attendanceService";
import { getClassListByGrade } from "util/APIs";
import styles from "../styles.module.css";

const ExportAttendanceModal = memo(({ isModalOpen, onCloseModal }) => {
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;
  const { gradeList } = useAttendanceConext();
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [gradeId, setGradeId] = useState(null);
  const [classId, setClassId] = useState(null);
  const [classList, setClassList] = useState(null);

  const handleCancel = () => {
    setSelectedDate(null);
    setClassId(null);
    onCloseModal();
  };

  const handleOk = async () => {
    try {
      setLoading(true);

      const params = {
        "filters[grades.id]": gradeId,
        "filters[classes.id]": classId,
        "filters[month]": moment(selectedDate).format("YYYY-MM-DD"),
      };
      const res = await attendanceService.exportAttendanceAbsent(
        accountId,
        params
      );
      if (res.status === 200) {
        window.open(res.data.data, "_blank");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
      handleCancel();
    }
  };

  const handleSetSelectedDate = useCallback((value) => {
    const temp = moment(value).format();
    setSelectedDate(temp);
  }, []);

  const handleSetGrade = useCallback((value) => {
    setGradeId(value);
    setClassId(null);
  }, []);

  const disableBtn = useMemo(() => {
    return !(classId && selectedDate);
  }, [classId, selectedDate]);

  const getClass = useCallback(
    async (gradeId) => {
      const params = {
        orderBy: "name",
      };
      const res = await getClassListByGrade(accountId, gradeId, params);
      if (res?.status === 200) {
        const data = res?.data?.data;
        setClassList(data);
        if (data?.length > 0) {
          setClassId(data[0]?.id);
        }
      }
    },
    [accountId]
  );
  useEffect(() => {
    if (isModalOpen) {
      if (gradeList.length > 0) {
        setGradeId(gradeList?.[0]?.id);
      }
    }
  }, [gradeList, isModalOpen]);

  useEffect(() => {
    if (isModalOpen && gradeId) {
      if (gradeId) {
        getClass(gradeId);
      }
    }
  }, [getClass, gradeId, isModalOpen]);

  return (
    <Modal
      style={{ marginTop: "-70px" }}
      width={1000}
      title="Xuất báo cáo chuyên cần"
      open={isModalOpen}
      onOk={loading ? null : handleOk}
      destroyOnClose={true}
      onCancel={handleCancel}
      okButtonProps={{
        disabled: disableBtn,
        className: disableBtn ? styles.disableBtn : "",
      }}
      maskClosable={false}
    >
      <Spin spinning={loading} tip="Đang xuất dữ liệu. Vui lòng đợi...">
        <InfoFieldSelect
          label="Chọn khối"
          data={gradeList}
          value={gradeId}
          setValue={handleSetGrade}
          direction="vertical"
        />
        <br />
        <InfoFieldSelect
          label="Chọn Lớp"
          data={classList}
          value={classId}
          setValue={setClassId}
          direction="vertical"
        />
        <br />
        <InfoFieldDatePicker
          label={"Chọn tháng"}
          value={selectedDate}
          setValue={handleSetSelectedDate}
          placeholder="Chọn thời gian"
          direction="vertical"
          className="flex-1"
          picker={"month"}
          format="MM/YYYY"
        />
      </Spin>
    </Modal>
  );
});

export default ExportAttendanceModal;
