import { noDataBox } from "asset/img/Images";
import Img from "common/image/Img";
import React from "react";
import styles from "./styles.module.css";

export default function NoDataBox({ style = "", image = noDataBox }) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <div
      className={`flex flex-col justify-center items-center mTop40 ${style}`}
    >
      <div className={styles.noDataBox}>
        <Img src={image} alt="No data" />
      </div>
      <span className={styles.noDataText}>No Data</span>
    </div>
  );
}
