import { Button, Space } from "antd";
import { Notification } from "common/Notification";
import moment from "moment";
import { useAttendanceConext } from "page/diemDanh/Context";
import React, { memo, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { updateAttendanceDaySchool } from "util/APIs";
import styles from "../../../styles.module.css";

const BoardingAttendanceField = memo(({ value, record }) => {
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;
  const { selectedDate, attendanceEventData, setIsRefresh } =
    useAttendanceConext();
  const [loading, setLoading] = useState(false);

  const handleAttendanceCheck = useCallback(
    async (isChecked, data, status) => {
      if (attendanceEventData?.attendance === false) {
        setLoading(true);
        const item = {
          boarding_attendance:
            isChecked && status === "isPresent" ? true : false,
          date: moment(selectedDate).format("YYYY-MM-DD"),
        };
        const payload = {
          data: item,
        };
        try {
          const res = await updateAttendanceDaySchool(
            accountId,
            data.boarding_attendance_id,
            payload
          );
          if (res?.status === 200) {
            Notification.sendSuccess("Điểm danh thành công");
            setIsRefresh(true);
          }
        } catch (err) {
        } finally {
          setLoading(false);
        }
      } else {
        Notification.sendWarning(
          "Không thể thay đổi sau khi điểm danh bán trú được tổng hợp"
        );
      }
    },
    [attendanceEventData?.attendance, selectedDate, accountId, setIsRefresh]
  );

  const isPresent = useMemo(() => {
    return value?.boarding_attendance === true;
  }, [value?.boarding_attendance]);

  const isNoAuthLeave = useMemo(() => {
    return value?.boarding_attendance === false;
  }, [value?.boarding_attendance]);

  return (
    <>
      <Space size={"middle"}>
        <Button
          shape="circle"
          className={`late border-0 ${
            isPresent ? styles.presentActive : styles.attendanceButtonDefault
          }`}
          disabled={loading}
          onClick={
            loading
              ? null
              : () => handleAttendanceCheck(!isPresent, record, "isPresent")
          }
        >
          N
        </Button>
        <Button
          shape="circle"
          className={`noAuthLeave border-0 ${
            isNoAuthLeave
              ? styles.noAuthLeaveActive
              : styles.attendanceButtonDefault
          }`}
          disabled={loading}
          onClick={
            loading
              ? null
              : () =>
                  handleAttendanceCheck(!isNoAuthLeave, record, "isNoAuthLeave")
          }
        >
          K
        </Button>
      </Space>
    </>
  );
});

export default BoardingAttendanceField;
