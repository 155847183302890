import { Radio } from "antd";
import { Notification } from "common/Notification";
import React, { memo, useCallback, useEffect, useState } from "react";
import { lunchRegistryService } from "services/lunchRegistryService";

const DishItem = memo(({ data, accountId, studentId, isBanTru, onSuccess }) => {
    const [selectedValue, setSelectedValue] = useState(undefined);
    const [lunchRegistryId, setLunchRegistryId] = useState(undefined);

    const handleChange = useCallback(
        async (e) => {
            const checkedValue = e.target.value;
            setSelectedValue(checkedValue);
            const payload = {
                data: {
                    dishesId: checkedValue,
                    studentId,
                    date: data?.[0],
                    status: "no_lunch",
                },
            };

            if (lunchRegistryId) {
                const response =
                    await lunchRegistryService.studentUpdateLunchByWeek(
                        accountId,
                        lunchRegistryId,
                        payload
                    );
                if (response?.status === 200) {
                    if (response?.data?.message) {
                        Notification.sendError(response?.data?.message);
                    } else {
                        Notification.sendSuccess("Cập nhật món thành công.");
                    }
                    setTimeout(() => {
                        onSuccess();
                    }, 500);
                }
            } else {
                const response =
                    await lunchRegistryService.studentRegisterLunchByWeek(
                        accountId,
                        payload
                    );
                if (response?.status === 200) {
                    if (response?.data?.message) {
                        Notification.sendError(response?.data?.message);
                    } else {
                        Notification.sendSuccess("Đăng ký món thành công.");
                    }
                    setTimeout(() => {
                        onSuccess();
                    }, 500);
                }
            }
        },
        [accountId, data, lunchRegistryId, onSuccess, studentId]
    );

    useEffect(() => {
        const selectedDishItem = data?.[1]?.find((item) => item.selectedDishId);
        if (selectedDishItem) {
            setSelectedValue(Number(selectedDishItem?.selectedDishId));
            setLunchRegistryId(selectedDishItem?.lunchRegistryId);
        }
    }, [data]);

    return (
        <Radio.Group
            onChange={handleChange}
            value={selectedValue}
            disabled={!isBanTru}
        >
            {data?.[1]?.map((option) => (
                <Radio value={option?.id} key={option?.id}>
                    {option.dishes}
                </Radio>
            ))}
        </Radio.Group>
    );
});

export default DishItem;
