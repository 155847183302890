import NoDataBox from "common/noDataBox/NoDataBox";
import SpinLoading from "common/spinLoading";
import _groupBy from "lodash/groupBy";
import DishItem from "page/studentLunchRegisterPage/components/DishItem";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { menuService } from "services/menuService";
import { formatDateValue } from "util/common";
import styles from "../styles.module.css";

const dayOfWeeks = [
    "Chủ nhật",
    "Thứ hai",
    "Thứ ba",
    "Thứ tư",
    "Thứ năm",
    "Thứ sáu",
    "Thứ bảy",
];

const DisheListItem = memo(({ startDateWeek, endDateWeek }) => {
    const [loading, setLoading] = useState(false);
    const userInfo = useSelector((state) => state?.app?.userInfo);
    const accountId = userInfo?.account?.id;
    const studentId = userInfo?.user?.id;
    const isBanTru = userInfo?.user?.ban_tru;
    const [data, setData] = useState({});

    const fetchDataMenu = useCallback(async () => {
        setLoading(true);
        const params = {
            "filters[granted]": true,
            "filters[date][$gte]": startDateWeek,
            "filters[date][$lte]": endDateWeek,
            orderBy: "date",
            studentId,
        };
        const response = await menuService.getMenuListByStudent(
            accountId,
            params
        );
        if (response?.status === 200) {
            const groupedData = _groupBy(response.data.data, "date");
            setData(groupedData);
        }
        setLoading(false);
    }, [startDateWeek, endDateWeek, studentId, accountId]);

    useEffect(() => {
        fetchDataMenu();
    }, [fetchDataMenu]);

    return (
        <>
            {loading ? (
                <SpinLoading isLoading={loading} />
            ) : (
                <>
                    {Object.entries(data).length === 0 ? (
                        <NoDataBox />
                    ) : (
                        <table className={styles.tableWrapper}>
                            <thead>
                                <tr>
                                    <th className={styles.thWrapper}>Thứ</th>
                                    <th className={styles.thWrapper}>Ngày</th>
                                    <th className={styles.thWrapper}>Món ăn</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(data).map((item, index) => (
                                    <tr key={item?.[0]}>
                                        <td className={styles.tdWrapper}>
                                            {dayOfWeeks[index]}
                                        </td>
                                        <td className={styles.tdWrapper}>
                                            {formatDateValue(item?.[0])}
                                        </td>
                                        <td className={styles.tdWrapper}>
                                            <DishItem
                                                data={item}
                                                isBanTru={isBanTru}
                                                accountId={accountId}
                                                studentId={studentId}
                                                onSuccess={fetchDataMenu}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </>
            )}
        </>
    );
});

export default DisheListItem;
