import React, { useCallback, useEffect } from "react";
import styles from "./styles.module.css";
import InfoFieldDatePicker from "common/CusInfoField/infoFieldDatePicker/InfoFieldDatePicker";
import moment from "moment";
import { useState } from "react";
import { useMemo } from "react";
import { TABLE_PAGE_SIZES, is1920OrMore } from "util/constant";
import { useSelector } from "react-redux";
import { createMenu, deleteMenu, getMenu, updateMenu } from "util/APIs";
import CusTable from "common/CusTable";
import { Button, Form, Input, Modal, Space, Select } from "antd";
import { HEADER_TYPE } from "mobile/util/mobileConstant";
import { routes } from "util/routes";

import { closeIconThin, newEditIcon } from "asset/img/Images";
import { Notification } from "common/Notification";
import IsMobile from "hook/isMobile";
import MobileHeader from "mobile/layout/header/MobileHeader";
import CustomBreadcrumb from "common/breadcrumb/CustomBreadcrumb";

export default function CanteenViewPage() {
  const [form] = Form.useForm();
  const [data, setData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(moment().format());
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZES[1]);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [recordId, setRecordId] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);
  const [haveEdit, setHaveEdit] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const isUsingMobile = IsMobile();

  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;

  const handleSetSelectedDate = (value) => {
    const temp = moment(value).format();
    setSelectedDate(temp);
  };

  const responsive = {
    setResponsiveCol: () => {
      if (!is1920OrMore) {
        // columns[1].width = 120;
        // columns[2].width = 120;
        // columns[3].width = 120;
      }
    },
    nodeWrapperWidth: () => {
      if (is1920OrMore) return "560px";
      if (!is1920OrMore) return "560px";
    },
    tableScroll: () => {
      if (is1920OrMore) return {};
      else
        return {
          x: 1000,
          y: 490,
        };
    },
  };

  const columns = useMemo(() => {
    let columnLst = [
      {
        title: "STT",
        key: "STT",
        width: 50,
        align: "center",
        render: (value, record, index) =>
          (currentPage - 1) * pageSize.value + index + 1,
      },
      {
        title: "Ngày",
        key: "date",
        width: 100,
        align: "center",
        dataIndex: "date",
        render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
      },
      {
        title: "Tên món ăn",
        key: "foods",
        align: "center",
        dataIndex: "dishes",
        width: 100,
        render: (text, record) => <span>{text}</span>,
      },
      {
        title: "Trạng thái",
        key: "status",
        align: "center",
        dataIndex: "workflow_state",
        width: 100,
        render: (text, record) => {
          if (text === "active") {
            return <span>Khả dụng</span>;
          } else {
            return <span>Không khả dụng</span>;
          }
        },
      },
      {
        title: "Số lượng đăng ký",
        key: "number_regis",
        align: "center",
        dataIndex: "total_registry",
        width: 100,
      },
      {
        title: "",
        dataIndex: "id",
        align: "center",
        width: 30,
        render: (_, record) => {
          const handleShowModalEdit = () => {
            setShowModalEdit(true);
            setRecordId(record.id);
            form.setFieldsValue({
              dishesName: record.dishes,
              status: record.workflow_state,
            });
          };
          return (
            <Space size={2}>
              <Button type="link" onClick={handleShowModalEdit}>
                <img src={newEditIcon} alt="Edit" className="editIcon" />
              </Button>
            </Space>
          );
        },
      },
      // {
      //   title: "",
      //   dataIndex: "id",
      //   align: "center",
      //   width: 30,
      //   render: (_, record) => {
      //     const handleShowModalDelete = () => {
      //       setShowModalDelete(true);
      //       setDeleteItem(record);
      //     };
      //     return (
      //       <Space size={2}>
      //         <Button type="link" onClick={handleShowModalDelete}>
      //           <img src={closeIconThin} alt="Close" className="closeIcon" />
      //         </Button>
      //       </Space>
      //     );
      //   },
      // },
    ];
    return columnLst;
  }, [currentPage, form, pageSize.value]);

  const getMenuData = useCallback(
    async (page = null) => {
      if (page === null) page = currentPage;
      const params = {
        "pagination[pageSize]": pageSize.value,
        "pagination[page]": page,
        "filters[date]": moment(selectedDate).format("YYYY-MM-DD"),
      };
      const res = await getMenu(accountId, params);
      if (res?.status === 200) {
        setData(res?.data.data);
        setTotalItems(res?.data.pagination?.total);
        setCurrentPage(res?.data.pagination?.page);
      }
    },
    [accountId, currentPage, pageSize.value, selectedDate]
  );

  const handleCloseModalEdit = () => {
    setShowModalEdit(false);
    form.resetFields();
  };

  const handleAddMenu = async () => {
    try {
      const values = await form.validateFields();
      const params = {
        data: {
          date: moment(selectedDate).format("YYYY-MM-DD"),
          dishes: values.newFood,
          workflow_state: "active",
        },
      };
      const res = await createMenu(accountId, params);
      if (res?.status === 200) {
        Notification.sendSuccess("Thêm thành công!");
        setHaveEdit((prev) => !prev);
        setShowModalAdd(false);
        form.resetFields();
      } else {
      }
    } catch (errorInfo) {
      console.log("Validation failed:", errorInfo);
    }
  };
  const { Option } = Select;
  const handleEditMenu = async () => {
    try {
      setLoadingEdit(true);
      const values = await form.validateFields();
      console.log(values);
      const params = {
        data: {
          date: moment(selectedDate).format("YYYY-MM-DD"),
          dishes: values.dishesName,
          workflow_state: values.status,
        },
      };
      const res = await updateMenu(accountId, recordId, params);
      if (res?.status === 200) {
        Notification.sendSuccess("Chỉnh sửa thành công!");
        setHaveEdit((prev) => !prev);
        setShowModalEdit(false);
      } else {
      }
    } catch (error) {
    } finally {
      setLoadingEdit(false);
    }
  };

  const handleDeleteMenu = async () => {
    try {
      setLoadingDelete(true);
      if (deleteItem) {
        const res = await deleteMenu(accountId, deleteItem.id);
        if (res?.status === 200) {
          Notification.sendSuccess("Xóa thành công!");
          setHaveEdit((prev) => !prev);
          setShowModalDelete(false);
        }
      }
    } catch (error) {
    } finally {
      setLoadingDelete(false);
    }
  };
  useEffect(() => {
    getMenuData();
  }, [getMenuData, haveEdit]);
  const onStatusChange = (value) => {
    switch (value) {
      case "active":
        form.setFieldsValue({
          status: "active",
        });
        break;
      case "unactive":
        form.setFieldsValue({
          status: "unactive",
        });
        break;
      default:
    }
  };
  return (
    <div className={`flex flex-col h-full`}>
      {isUsingMobile ? (
        <MobileHeader
          type={HEADER_TYPE.middleTitle}
          title="Thực đơn nhà ăn"
          haveNotiButton={false}
        />
      ) : (
        <CustomBreadcrumb crumbs={[routes.CANTEEN_DETAIL]} />
      )}
      <div className={`py-4 section-shadow bg-white`}>
        <div className={`${styles.wrapper}`}>
          {isUsingMobile ? (
            ""
          ) : (
            <h2 className={`${styles.header}`}>Thực đơn nhà ăn </h2>
          )}
          <div className={`${styles.wrapperFilters}`}>
            <Button
              type="primary"
              className={`${styles.customButton}`}
              onClick={() => setShowModalAdd(true)}
            >
              Thêm món mới
            </Button>
            <InfoFieldDatePicker
              label={"Ngày"}
              value={selectedDate}
              setValue={handleSetSelectedDate}
              placeholder="Chọn thời gian"
              direction="vertical"
              className="flex-1"
              picker={"date"}
            />
          </div>
        </div>
        <div className={`mx-6 mt-5`}>
          <CusTable
            rowKey="id"
            data={data}
            havePagination={totalItems > pageSize.value}
            columns={columns}
            rowClassName="editable-row"
            page={currentPage}
            setPage={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            totalItems={totalItems}
            scroll={responsive.tableScroll()}
          />
        </div>
        <Modal
          style={{ marginTop: "-70px" }}
          width={600}
          title="Chỉnh sửa thông tin món ăn"
          open={showModalEdit}
          // onOk={handleOk}
          onCancel={handleCloseModalEdit}
          footer={[
            <Button key="cancel" onClick={handleCloseModalEdit}>
              Hủy
            </Button>,
            <Button
              key="send"
              type="primary"
              onClick={handleEditMenu}
              disabled={loadingEdit}
            >
              Xác nhận
            </Button>,
          ]}
        >
          <Form form={form}>
            <Form.Item
              name="dishesName"
              label="Tên món ăn"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập tên Món ăn!",
                },
                {
                  max: 125,
                  message: "Giới hạn 125 ký tự",
                },
                {
                  pattern: /^(?!\s+$).+$/,
                  message: "Lỗi: Tên không phù hợp",
                },
              ]}
              className={`${styles.customInput}`}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="status"
              label="Trạng thái"
              rules={[{ required: true }]}
              style={{ marginLeft: "8px" }}
            >
              <Select
                placeholder="Chỉnh sửa trạng thái"
                onChange={onStatusChange}
                allowClear
                style={{ height: "35px" }}
                defaultValue=""
              >
                <Option value="active">Khả dụng</Option>
                <Option value="unactive">Không khả dụng</Option>
              </Select>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          style={{ marginTop: "-70px" }}
          width={600}
          title="Thêm món ăn mới"
          open={showModalAdd}
          // onOk={handleOk}
          onCancel={() => setShowModalAdd(false)}
          footer={[
            <Button key="cancel" onClick={() => setShowModalAdd(false)}>
              Hủy
            </Button>,
            <Button key="send" type="primary" onClick={handleAddMenu}>
              Xác nhận
            </Button>,
          ]}
        >
          <Form form={form} name="dishesAddForm">
            <Form.Item
              name="newFood"
              label="Tên món ăn"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập tên Món ăn!",
                },
                {
                  max: 125,
                  message: "Giới hạn 125 ký tự",
                },
                {
                  pattern: /^(?!\s+$).+$/,
                  message: "Lỗi: Tên không phù hợp",
                },
              ]}
              className={`${styles.customInput}`}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
}
