import { Button, Space } from "antd";
import { Notification } from "common/Notification";
import moment from "moment";
import { useAttendanceConext } from "page/diemDanh/Context";
import React, { memo, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  updateAttendanceOneStudent,
  updateAttendanceStatusByDate,
} from "util/APIs";
import { ATTENDANCE_STATUS, STATUS_ATTENDANCE } from "util/constant";
import styles from "../../../styles.module.css";

const WorkflowState = memo(({ value, record }) => {
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;
  const { selectedDate, attendanceEventData, setIsRefresh } =
    useAttendanceConext();
  const [loading, setLoading] = useState(false);

  const handleAttendanceCheck = useCallback(
    async (isChecked, data, attendance_status) => {
      setLoading(true);
      if (attendanceEventData?.attendance === false) {
        const item = {
          date: moment(selectedDate).format("YYYY-MM-DD"),
          studentId: data?.attendance_patrol?.[0].studentId,
          classId: data?.attendance_patrol?.[0].classId,
          sessionId: data?.attendance_patrol?.[0].sessionId,
          workflow_state: isChecked
            ? attendance_status
            : STATUS_ATTENDANCE.unmarked,
        };
        const payload = {
          data: item,
        };
        try {
          const res = await updateAttendanceStatusByDate(
            accountId,
            data?.attendance_patrol?.[0].id,
            payload
          );
          if (res?.status === 200) {
            setIsRefresh?.(true);
            Notification.sendSuccess("Điểm danh thành công");
          }
        } catch (err) {
        } finally {
          setLoading(false);
        }
      } else {
        const item = {
          date: selectedDate,
          students: data?.students?.[0].id,
          sessions: data?.sessions?.[0].id,
          lessons: data?.lessons?.[0].id,
          workflow_state: isChecked
            ? attendance_status
            : STATUS_ATTENDANCE.unmarked,
          staff: [userInfo?.user?.id],
        };
        const payload = {
          data: item,
        };
        try {
          const res = await updateAttendanceOneStudent(
            accountId,
            data.id,
            payload
          );
          if (res?.status === 200) {
            setIsRefresh?.(true);
            Notification.sendSuccess("Điểm danh thành công");
          }
        } catch (err) {
        } finally {
          setLoading(false);
        }
      }
    },
    [
      accountId,
      attendanceEventData?.attendance,
      selectedDate,
      setIsRefresh,
      userInfo?.user?.id,
    ]
  );

  const isLate = useMemo(() => {
    return value?.workflow_state === STATUS_ATTENDANCE.late;
  }, [value?.workflow_state]);

  const isAnualLeave = useMemo(() => {
    return value?.workflow_state === STATUS_ATTENDANCE.author_absent;
  }, [value?.workflow_state]);

  const isPresent = useMemo(() => {
    return value?.workflow_state === STATUS_ATTENDANCE.present;
  }, [value?.workflow_state]);

  const isNoAuthLeave = useMemo(() => {
    return value?.workflow_state === STATUS_ATTENDANCE.unauthor_absent;
  }, [value?.workflow_state]);

  return (
    <Space size={"middle"}>
      <Button
        shape="circle"
        className={`late border-0 ${
          isPresent ? styles.presentActive : styles.attendanceButtonDefault
        }`}
        disabled={loading}
        onClick={
          loading
            ? null
            : () =>
                handleAttendanceCheck(
                  !isPresent,
                  record,
                  ATTENDANCE_STATUS[0].key
                )
        }
      >
        C
      </Button>
      <Button
        shape="circle"
        className={`late border-0 ${
          isLate ? styles.lateActive : styles.attendanceButtonDefault
        }`}
        disabled={loading}
        onClick={
          loading
            ? null
            : () =>
                handleAttendanceCheck(!isLate, record, ATTENDANCE_STATUS[1].key)
        }
      >
        T
      </Button>
      <Button
        shape="circle"
        className={`anualLeave border-0 ${
          isAnualLeave
            ? styles.anualLeaveActive
            : styles.attendanceButtonDefault
        }`}
        disabled={loading}
        onClick={
          loading
            ? null
            : () =>
                handleAttendanceCheck(
                  !isAnualLeave,
                  record,
                  ATTENDANCE_STATUS[2].key
                )
        }
      >
        P
      </Button>
      <Button
        shape="circle"
        className={`noAuthLeave border-0 ${
          isNoAuthLeave
            ? styles.noAuthLeaveActive
            : styles.attendanceButtonDefault
        }`}
        disabled={loading}
        onClick={
          loading
            ? null
            : () =>
                handleAttendanceCheck(
                  !isNoAuthLeave,
                  record,
                  ATTENDANCE_STATUS[3].key
                )
        }
      >
        K
      </Button>
    </Space>
  );
});

export default WorkflowState;
