import { post } from "util/APICaller";
import { API_URL } from "util/constant";

const changePassword = (body) => {
  return post(`${API_URL}/auth/change-password`, body);
};

export const userService = {
  changePassword,
};
