import moment from "moment";
import { useAttendanceConext } from "page/diemDanh/Context";
import Inner from "page/diemDanh/tabs/dateAttendance/Inner";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getAttendanceByDay,
  getAttendanceEvent,
  getAttendanceListByClass,
  getNumberOfAttendancesByCard,
} from "util/APIs";
import { TABLE_PAGE_SIZES } from "util/constant";

const DateAttendance = memo(() => {
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;
  const {
    selectedDate,
    classId,
    sectionId,
    lessonId,
    isRefresh,
    setAttendanceEventData,
    setIsRefresh,
  } = useAttendanceConext();

  const [tableLoading, setTableLoading] = useState(false);
  const [attendanceData, setAttendanceData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZES[0]);
  const [totalAttendanceStudents, setTotalAttendancesStudents] = useState(0);
  const [totalStudents, setTotalStudents] = useState(0);

  const fetchData = useCallback(
    async (page = null) => {
      if (!lessonId) return;
      try {
        if (page === null) page = currentPage;
        setTableLoading(true);
        const original = {
          "pagination[pageSize]": pageSize.value,
          "pagination[page]": page,
        };
        const aeParams = {
          "filters[sessionId]": sectionId,
          "filters[date][$eq]": moment(selectedDate).format("YYYY-MM-DD"),
        };
        const attendanceEvent = await getAttendanceEvent(accountId, aeParams);
        const ae = attendanceEvent.data?.data?.[0];
        setAttendanceEventData(ae);

        if (ae?.attendance === false) {
          setAttendanceData([]);
          const params = {
            ...original,
            "filters[date][$eq]": moment(selectedDate).format("YYYY-MM-DD"),
            "filters[sessionId]": sectionId,
            "filters[lessonId]": lessonId,
            orderBy: "stt",
          };
          if (classId) {
            const res = await getAttendanceByDay(accountId, classId, params);
            if (res?.status === 200) {
              setAttendanceData(res?.data?.data);
              let resByDay = res.data.data;
              const presentLst = resByDay.filter(
                (item) => item.workflow_state === "present"
              );
              setTotalAttendancesStudents(presentLst.length);
            }
          }
        } else {
          setAttendanceData([]);
          const params = {
            ...original,
            "filters[date][$eq]": moment(selectedDate).format("YYYY-MM-DD"),
            "filters[sessions.id][$in]": sectionId,
            "filters[lessons.id][$in]": lessonId,
          };
          if (classId) {
            const res = await getAttendanceListByClass(
              accountId,
              classId,
              params
            );
            if (res?.status === 200) {
              res?.data.data.sort(
                (a, b) => a.students[0].stt - b.students[0].stt
              );
              if (res?.status === 200) {
                setAttendanceData(res?.data.data);
                let resByDay = res.data.data;
                const presentLst = resByDay.filter(
                  (item) => item.workflow_state === "present"
                );
                setTotalAttendancesStudents(presentLst.length);
              }
            }
          }
        }
      } catch (error) {
      } finally {
        setTableLoading(false);
      }
    },
    [
      accountId,
      classId,
      currentPage,
      lessonId,
      pageSize.value,
      sectionId,
      selectedDate,
      setAttendanceEventData,
    ]
  );

  const getNumberOfStudents = useCallback(async () => {
    if (!lessonId || !classId) return;
    const params = {
      // "filters[type][$eq]": "class",
      "filters[sessions.id][$eq]": sectionId,
      "filters[date][$eq]": moment(selectedDate).format("YYYY-MM-DD"),
      "filters[lessons.id][$in]": lessonId,
    };
    try {
      const response = await getNumberOfAttendancesByCard(
        accountId,
        classId,
        params
      );
      if (response?.status === 200) {
        setTotalStudents(response.data.data.totalStudents);
      }
    } catch (err) {}
  }, [sectionId, selectedDate, lessonId, classId, accountId]);

  useEffect(() => {
    getNumberOfStudents();
  }, [getNumberOfStudents]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (isRefresh) {
      fetchData();
      setIsRefresh(false);
    }
  }, [fetchData, isRefresh, setIsRefresh]);

  return (
    <Inner
      totalAttendanceStudents={totalAttendanceStudents}
      totalStudents={totalStudents}
      data={attendanceData}
      tableLoading={tableLoading}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      pageSize={pageSize}
      setPageSize={setPageSize}
      totalItems={totalItems}
    />
  );
});

export default DateAttendance;
