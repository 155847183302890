export const IS_LOCAL_LOGIN = "isLocalLogin";

export const USER_ROLE = {
  QUAN_TRI_VIEN: "quan_tri_vien",
  GIAM_THI_TONG: "giam_thị_tổng",
  GIAM_THI: "giam_thi",
  GIAO_VIEN: "giao_vien",
  NHA_AN: "nha_an",
  PHU_HUYNH: "phu_huynh",
  HOC_SINH: "học_sinh",
};

export const SESSION_ID = {
  BUOI_SANG: 1,
  BUOI_CHIEU: 2,
  BUOI_TRUA: 5,
};

export const BIEU_MAU_XIN_PHEP_LINK =
  "https://sharing.cohota.com/thuthiem/donxinphepnghihoc.pdf";

export const DANH_SACH_HOC_SINH_BAN_TRU_TEMPLATE =
  "https://storage.cohota.com/sharing.cohota.com/thuthiem/mau_ban_tru.xlsx";
