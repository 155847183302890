import { Button } from "antd";
import ImportBoardingStudentModal from "page/diemDanh/header/ImportBoardingStudentModal";
import React, { memo, useCallback, useState } from "react";
import styles from "../styles.module.css";

const ImportBoardingButton = memo(() => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <div className="flex items-end justify-end">
      <Button
        className={`w-full flex justify-center ${styles.searchBtn}`}
        onClick={handleOpenModal}
        size="large"
      >
        <span className={`${styles.searchBtnText}`}>Nhập dữ liệu</span>
      </Button>

      <ImportBoardingStudentModal visible={isOpen} onClose={handleOpenModal} />
    </div>
  );
});

export default ImportBoardingButton;
