import moment from "moment";
import { useAttendanceConext } from "page/diemDanh/Context";
import Inner from "page/diemDanh/tabs/weekAttendance/Inner";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getStudentListByClass } from "util/APIs";
import { TABLE_PAGE_SIZES } from "util/constant";

const WeekAttendance = memo(() => {
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;
  const {
    selectedDate,
    classId,
    sectionId,
    lessonId,
    isRefresh,
    setIsRefresh,
  } = useAttendanceConext();

  const [tableLoading, setTableLoading] = useState(false);
  const [attendanceData, setAttendanceData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZES[0]);

  const fetchData = useCallback(
    async (page = null) => {
      if (!lessonId || !classId) return;
      try {
        if (page === null) page = currentPage;
        setTableLoading(true);

        const startOfWeek = moment(selectedDate)
          .startOf("week")
          .format("YYYY-MM-DD");
        const endOfWeek = moment(selectedDate)
          .endOf("week")
          .format("YYYY-MM-DD");
        let params = {
          "pagination[pageSize]": pageSize.value,
          "pagination[page]": page,
          "filters[attendances.date][$gte]": startOfWeek,
          "filters[attendances.date][$lte]": endOfWeek,
          sessionId: sectionId,
          lessonId: lessonId,
          "populate[]": "attendances",
        };
        const res = await getStudentListByClass(accountId, classId, params);
        if (res?.status === 200) {
          const { data, pagination } = res?.data;
          setAttendanceData(data);
          setTotalItems(pagination?.total);
          setCurrentPage(pagination?.page);
        }
      } catch (error) {
      } finally {
        setTableLoading(false);
      }
    },
    [
      currentPage,
      selectedDate,
      pageSize.value,
      sectionId,
      lessonId,
      classId,
      accountId,
    ]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (isRefresh) {
      fetchData();
      setIsRefresh(false);
    }
  }, [fetchData, isRefresh, setIsRefresh]);

  return (
    <Inner
      data={attendanceData}
      tableLoading={tableLoading}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      pageSize={pageSize}
      setPageSize={setPageSize}
      totalItems={totalItems}
    />
  );
});

export default WeekAttendance;
