import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  setSidebarActiveKey,
  setSidebarOpenKeys,
  setUserInfo,
} from "redux/action/app";
import { getTeacherData } from "util/APIs";
import { getRoleType } from "util/common";
import { IS_LOCAL_LOGIN, USER_ROLE } from "util/constants";
import { routes } from "util/routes";

export default function useHandleLogin() {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLoginSuccess = async (data) => {
    try {
      localStorage.setItem("token", data?.jwt);
      const userData = await getTeacherData();
      const isLocalLogin = localStorage.getItem(IS_LOCAL_LOGIN);
      localStorage.setItem("user_info", JSON.stringify(userData?.data));
      dispatch(
        setUserInfo({
          ...(userData?.data || {}),
          isLocalLogin: isLocalLogin ? true : false,
        })
      );

      const userRole = getRoleType(userData?.data?.user?.roles);
      switch (userRole) {
        case USER_ROLE.QUAN_TRI_VIEN:
          dispatch(setSidebarOpenKeys(["students_management"]));
          dispatch(setSidebarActiveKey("attendance"));
          history.push(routes.ATTENDANCE);
          break;
        case USER_ROLE.GIAM_THI_TONG:
          dispatch(setSidebarOpenKeys(["students_management"]));
          dispatch(setSidebarActiveKey("attendance"));
          history.push(routes.ATTENDANCE);
          // dispatch(setSidebarOpenKeys(["canteen_management"]));
          // dispatch(setSidebarActiveKey("weekly_dish_list"));
          // history.push(routes.ADMIN_CANTEEN);
          break;
        case USER_ROLE.GIAM_THI:
          dispatch(setSidebarActiveKey("personal_details"));
          history.push(routes.PROFILE);
          break;
        case USER_ROLE.GIAO_VIEN:
          dispatch(setSidebarActiveKey("personal_details"));
          history.push(routes.PROFILE);
          break;
        case USER_ROLE.NHA_AN:
          dispatch(setSidebarOpenKeys(["canteen_management_week"]));
          dispatch(setSidebarActiveKey("weekly_dish_list"));
          history.push(routes.CANTEEN_WEEK);
          break;
        case USER_ROLE.PHU_HUYNH:
          dispatch(setSidebarActiveKey("personal_details"));
          history.push(routes.PROFILE);
          break;
        case USER_ROLE.HOC_SINH:
          dispatch(setSidebarActiveKey("lunch_register"));
          history.push(routes.LUNCH_REGISTRY);
          break;
        default:
          history.push(routes.HOME);
      }
    } catch (error) {}
  };

  return [handleLoginSuccess];
}
