import { Modal, Radio, Spin } from "antd";
import InfoFieldDatePicker from "common/CusInfoField/infoFieldDatePicker/InfoFieldDatePicker";
import InfoFieldSelect from "common/CusInfoField/infoFieldSelect/InfoFieldSelect";
import { Notification } from "common/Notification";
import moment from "moment";
import { useAttendanceConext } from "page/diemDanh/Context";
import React, { memo, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { exportAttendance, getAttendanceEvent } from "util/APIs";
import styles from "../styles.module.css";

const DATE_TYPE = {
  DAY: "day",
  PERIOD: "period",
};
const ExportReportModal = memo(({ isModalOpen, onCloseModal }) => {
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;
  const { gradeList, sectionList } = useAttendanceConext();
  const [loading, setLoading] = useState(false);
  const [dateType, setDateType] = useState(DATE_TYPE.DAY);
  const [selectedDateExport, setSelectedDateExport] = useState(null);
  const [selectedDateFromExport, setSelectedDateFromExport] = useState(null);
  const [selectedDateToExport, setSelectedDateToExport] = useState(null);
  const [gradeExport, setGradeExport] = useState(null);
  const [sectionExport, setSectionExport] = useState(null);

  const handleCancel = () => {
    setDateType(DATE_TYPE.DAY);
    setSelectedDateFromExport(null);
    setSelectedDateToExport(null);
    setSelectedDateExport(null);
    onCloseModal();
  };

  const handleOk = async () => {
    try {
      setLoading(true);

      if (dateType === DATE_TYPE.DAY) {
        const params = {
          "filters[grades.id]": gradeExport,
          "filters[sessions.id]": sectionExport,
          "filters[date][$eq]": moment(selectedDateExport).format("YYYY-MM-DD"),
        };
        const res = await exportAttendance(accountId, params);
        if (res.status === 200) {
          window.open(res.data.data, "_blank");
        }
      } else if (dateType === DATE_TYPE.PERIOD) {
        const params = {
          "filters[grades.id]": gradeExport,
          "filters[sessions.id]": sectionExport,
        };
        if (selectedDateFromExport && selectedDateToExport) {
          params["filters[date][$gt]"] = moment(selectedDateFromExport).format(
            "YYYY-MM-DD"
          );
          params["filters[date][$lt]"] =
            moment(selectedDateToExport).format("YYYY-MM-DD");
        } else if (selectedDateFromExport) {
          params["filters[date][$eq]"] = moment(selectedDateFromExport).format(
            "YYYY-MM-DD"
          );
        } else if (selectedDateToExport) {
          params["filters[date][$eq]"] =
            moment(selectedDateToExport).format("YYYY-MM-DD");
        }
        const res = await exportAttendance(accountId, params);
        if (res.status === 200) {
          window.open(res.data.data, "_blank");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
      handleCancel();
    }
  };

  const handleDateTypeChange = (e) => {
    setDateType(e.target.value);
  };

  const handleSetSelectedDateExport = async (value) => {
    const temp = moment(value).format();
    // const isBoarding = sectionListFull.filter(
    //   (e) => e.id === sectionExport
    // )[0];
    // if (isBoarding?.session_code === "boarding") {
    //   setSelectedDateExport(temp);
    //   return
    // }
    const date = {
      "filters[sessionId]": sectionExport,
      "filters[date][$eq]": moment(temp).format("YYYY-MM-DD"),
    };
    const attendance_event = await getAttendanceEvent(accountId, date);
    if (attendance_event?.status === 200) {
      if (attendance_event.data?.data[0]?.attendance === true) {
        setSelectedDateExport(temp);
      } else {
        Notification.sendWarning(
          `Danh sách điểm danh ngày ${moment(temp).format(
            "DD-MM-YYYY"
          )} chưa được tổng hợp`
        );
      }
    }
  };

  const handleSelectDateFromExport = async (value) => {
    const temp = moment(value).format();
    // const isBoarding = sectionListFull.filter(
    //   (e) => e.id === sectionExport
    // )[0];
    // if (isBoarding?.session_code === "boarding") {
    //   setSelectedDateFromExport(temp);
    //   return
    // }
    const date = {
      "filters[sessionId]": sectionExport,
      "filters[date][$eq]": moment(temp).format("YYYY-MM-DD"),
    };
    const attendance_event = await getAttendanceEvent(accountId, date);
    if (attendance_event?.status === 200) {
      if (attendance_event.data?.data[0]?.attendance === true) {
        setSelectedDateFromExport(temp);
      } else {
        Notification.sendWarning(
          `Danh sách điểm danh ngày ${moment(temp).format(
            "DD-MM-YYYY"
          )} chưa được tổng hợp`
        );
      }
    }
  };

  const handleSelectDateToExport = async (value) => {
    const temp = moment(value).format();
    // const isBoarding = sectionListFull.filter(
    //   (e) => e.id === sectionExport
    // )[0];
    // if (isBoarding?.session_code === "boarding") {
    //   setSelectedDateToExport(temp);
    //   return
    // }
    const date = {
      "filters[sessionId]": sectionExport,
      "filters[date][$eq]": moment(temp).format("YYYY-MM-DD"),
    };
    const attendance_event = await getAttendanceEvent(accountId, date);
    if (attendance_event?.status === 200) {
      if (attendance_event.data?.data[0]?.attendance === true) {
        setSelectedDateToExport(temp);
      } else {
        Notification.sendWarning(
          `Danh sách điểm danh ngày ${moment(temp).format(
            "DD-MM-YYYY"
          )} chưa được tổng hợp`
        );
      }
    }
  };

  const handleSectionExport = (value) => {
    setSectionExport(value);
    setSelectedDateFromExport(null);
    setSelectedDateToExport(null);
    setSelectedDateExport(null);
  };

  const disableBtn = useMemo(() => {
    return !(
      selectedDateExport ||
      selectedDateFromExport ||
      selectedDateToExport
    );
  }, [selectedDateExport, selectedDateFromExport, selectedDateToExport]);

  useEffect(() => {
    if (isModalOpen) {
      if (gradeList.length > 0) {
        setGradeExport(gradeList?.[0]?.id);
      }
      if (setSectionExport.length > 0) {
        setSectionExport(sectionList?.[0]?.id);
      }
    }
  }, [gradeList, isModalOpen, sectionList]);

  return (
    <Modal
      style={{ marginTop: "-70px" }}
      width={1000}
      title="Xuất báo cáo"
      open={isModalOpen}
      onOk={loading ? null : handleOk}
      destroyOnClose={true}
      onCancel={handleCancel}
      okButtonProps={{
        disabled: disableBtn,
        className: disableBtn ? styles.disableBtn : "",
      }}
      maskClosable={false}
    >
      <Spin spinning={loading} tip="Đang xuất dữ liệu. Vui lòng đợi...">
        <InfoFieldSelect
          label="Chọn khối"
          data={gradeList}
          value={gradeExport}
          setValue={setGradeExport}
          direction="vertical"
        />
        <br />
        <InfoFieldSelect
          label="Chọn buổi"
          data={sectionList}
          value={sectionExport}
          setValue={handleSectionExport}
          direction="vertical"
          valueTextName="title"
          allowClear={false}
        />

        <br />
        <Radio.Group
          onChange={handleDateTypeChange}
          value={dateType}
          className="mb-5"
        >
          <Radio value={DATE_TYPE.DAY}>Chọn ngày</Radio>
          <Radio value={DATE_TYPE.PERIOD}>Chọn khoảng thời gian</Radio>
        </Radio.Group>
        {dateType === DATE_TYPE.DAY && (
          <>
            <InfoFieldDatePicker
              label={"Chọn ngày"}
              value={selectedDateExport}
              setValue={handleSetSelectedDateExport}
              placeholder="Chọn thời gian"
              direction="vertical"
              className="flex-1"
              picker={"date"}
            />
          </>
        )}

        {dateType === DATE_TYPE.PERIOD && (
          <div className="flex gap-2">
            <InfoFieldDatePicker
              label={"Từ Ngày"}
              value={selectedDateFromExport}
              setValue={handleSelectDateFromExport}
              placeholder="Chọn thời gian"
              direction="vertical"
              className="flex-1"
              picker={"date"}
            />
            <InfoFieldDatePicker
              label={"Tới ngày"}
              value={selectedDateToExport}
              setValue={handleSelectDateToExport}
              placeholder="Chọn thời gian"
              direction="vertical"
              className="flex-1"
              picker={"date"}
            />
          </div>
        )}
      </Spin>
    </Modal>
  );
});

export default ExportReportModal;
