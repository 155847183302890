import React, { memo } from "react";
import { Spin } from "antd";

const SpinLoading = memo(({ isLoading = true, className, ...props }) => {
    return (
        <div className="flex justify-center">
            <Spin
                spinning={isLoading}
                wrapperClassName={`${className}`}
                {...props}
            />
        </div>
    );
});

export default SpinLoading;
