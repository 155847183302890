import CusTable from "common/CusTable";
import IsMobile from "hook/isMobile";
import moment from "moment";
import { useAttendanceConext } from "page/diemDanh/Context";
import Filter from "page/diemDanh/component/Filter";
import AttendanceModal from "page/diemDanh/modal/AttendanceModal";
import React, { memo, useMemo } from "react";
import { formatDateValue } from "util/common";
import { getGenderText } from "util/helper";
import styles from "../../styles.module.css";

const Inner = memo(
  ({
    data,
    tableLoading,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    totalItems,
  }) => {
    const isUsingMobile = IsMobile();
    const { selectedDate } = useAttendanceConext();

    const columns = useMemo(() => {
      let columnLst = [
        {
          title: "STT",
          key: "STT",
          width: 50,
          align: "center",
          render: (value, record, index) =>
            (currentPage - 1) * pageSize.value + index + 1,
        },
        {
          title: "Họ",
          align: "center",
          width: 200,
          key: `last_name`,
          dataIndex: "last_name",
          // fixed: "left",
          render: (text, record) => <span>{text}</span>,
        },
        {
          title: "Tên",
          align: "center",
          width: 100,
          key: `first_name`,
          dataIndex: "first_name",
          render: (text, record) => <span>{text}</span>,
          className: styles.fixedCol,
        },
        {
          title: "Mã học sinh",
          width: 150,
          align: "center",
          dataIndex: "moeat_id",
          render: (text, record) => <span>{text}</span>,
        },
        {
          title: "Ngày sinh",
          dataIndex: "dob",
          width: 150,
          align: "center",
          render: (value) => <span>{formatDateValue(value)}</span>,
        },
        {
          title: "Giới tính",
          dataIndex: "gender",
          width: 150,
          align: "center",
          render: (text, record) => <span>{getGenderText(text)}</span>,
        },
        {
          title: "Ăn trưa",
          dataIndex: "attendances",
          width: 150,
          align: "center",
          render: (text, record) => {
            const foundItem = text?.find?.(
              (item) =>
                item?.date === moment(selectedDate).format("YYYY-MM-DD") &&
                item?.has_lunch &&
                item?.lunch_attendance
            );
            if (foundItem) {
              return "Có";
            } else {
              return "Không";
            }
          },
        },
      ];
      if (isUsingMobile) {
        columnLst = columnLst.filter((item) => item.dataIndex !== "dob");
      }
      return columnLst;
    }, [currentPage, isUsingMobile, pageSize.value, selectedDate]);

    return (
      <div className="px-8">
        <Filter />
        <AttendanceModal data={data} />

        <CusTable
          rowKey="id"
          data={data}
          columns={columns}
          loading={tableLoading}
          havePagination={totalItems > pageSize.value}
          rowClassName="bg-white"
          page={currentPage}
          setPage={setCurrentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalItems={totalItems}
          scroll={{ x: "1000px", y: 480 }}
        />
      </div>
    );
  }
);

export default Inner;
