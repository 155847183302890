import { Menu } from "antd";
import { isPermitted } from "authen/authenHelper";
import useIsSchoolType from "hook/isUniversity";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setSidebarOpenKeys } from "redux/action/app";
import { getRoleType } from "util/common";
import { PERMISSION_TYPE, UNIVERSITY } from "util/constant";
import { USER_ROLE } from "util/constants";
import { SIDEBAR_ITEMS } from "util/sidebars";
import SubMenu from "./components/SubMenu";

function SidebarBody() {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const history = useHistory();
  const dispatch = useDispatch();
  const isUniversity = useIsSchoolType(UNIVERSITY);
  const [hoverKey, setHoverKey] = useState(null);
  const collapse = useSelector((state) => state.app.sidebarCollapse);
  const school = useSelector((state) => state.school.selectedSchool);
  // const userInfo = JSON.parse(localStorage.getItem("user_info")) || [];
  // const isAdmin = userInfo?.role_key_list?.includes(ROLE_KEY_LIST.admin);
  const [authorizedSidebarItems, setAuthorizedSidebarItems] = useState([]);
  const sidebarOpenKeys = useSelector((state) => state.app.sidebarOpenKeys);
  const sidebarActiveKey = useSelector((state) => state.app.sidebarActiveKey);
  const userInfo = useSelector((state) => state?.app?.userInfo);
  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////
  useEffect(() => {
    const temp = [];

    const tabList = userInfo?.user?.tabs;
    const userRole = getRoleType(userInfo?.user?.roles);
    if (userRole === USER_ROLE.NHA_AN) {
      tabList[0].key = "canteen_management_week";
    }
    tabList?.forEach?.((tab) => {
      const foundItem = SIDEBAR_ITEMS.find((item) => item.key === tab?.key);
      if (foundItem) {
        temp.push(foundItem);
      }
    });
    setAuthorizedSidebarItems([...temp]);
    // setAuthorizedSidebarItems([...SIDEBAR_ITEMS]);
    // const currentSidebar = isAdmin
    //   ? SIDEBAR_ITEMS
    //   : isUniversity
    //   ? SIDEBAR_ITEMS
    //   : SIDEBAR_ITEMS;
    // currentSidebar?.forEach((father) => {
    //   const fatherItem = { ...father };
    //   let isPermit;
    //   if (fatherItem?.children && fatherItem.key !== "info-gate") {
    //     fatherItem.children = fatherItem.children?.filter((childItem) => {
    //       return checkIsPermittedForItem(childItem);
    //     });
    //     isPermit = fatherItem.children?.length > 0;
    //   } else {
    //     isPermit = checkIsPermittedForItem(fatherItem);
    //   }
    //   if (isPermit) {
    //     temp.push(fatherItem);
    //   }
    // });
  }, [history.location, userInfo?.user?.roles, userInfo?.user?.tabs]);
  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  const checkIsPermittedForItem = (item) => {
    const replacedKey = item?.key?.replace(/\-/g, "_"); // school-management => school_management
    return isPermitted(replacedKey, PERMISSION_TYPE.view);
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find(
      (key) => sidebarOpenKeys?.indexOf?.(key) === -1
    );
    let temp = [];
    const rootSubmenuKeys = authorizedSidebarItems?.map((x) => x.key);
    if (rootSubmenuKeys?.indexOf?.(latestOpenKey) === -1) {
      temp = [...keys];
    } else {
      temp = latestOpenKey ? [latestOpenKey] : [];
    }
    dispatch(setSidebarOpenKeys(temp));
  };
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////
  const renderMenu = (item) => (
    <SubMenu
      item={item}
      hoverKey={hoverKey}
      setHoverKey={setHoverKey}
      key={item.key}
      sidebarActiveKey={sidebarActiveKey}
    />
  );
  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <Menu
      mode="inline"
      openKeys={sidebarOpenKeys}
      selectedKeys={[sidebarActiveKey]}
      onOpenChange={onOpenChange}
      inlineCollapsed={collapse}
    >
      {authorizedSidebarItems?.map((item) => renderMenu(item))}
    </Menu>
  );
}

export default SidebarBody;
