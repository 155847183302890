import moment from "moment";
import { useAttendanceConext } from "page/diemDanh/Context";
import Inner from "page/diemDanh/tabs/boardingAttendance/Inner";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getAttendanceByClassDate,
  getAttendanceEvent,
  getAttendanceRoomId,
  getRoomAttendance,
} from "util/APIs";
import { TABLE_PAGE_SIZES } from "util/constant";
import { SESSION_ID } from "util/constants";

const BoardingAttendance = memo(() => {
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;
  const {
    selectedDate,
    roomId,
    setRoomId,
    setRoomList,
    isRefresh,
    setIsRefresh,
    setAttendanceEventData,
  } = useAttendanceConext();

  const [tableLoading, setTableLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZES[0]);
  const [studentList, setStudentList] = useState([]);
  const [totalAttendanceStudents, setTotalAttendanceStudents] = useState(0);

  const fetchData = useCallback(
    async (page = null) => {
      try {
        if (page === null) page = currentPage;
        setTableLoading(true);

        if (!roomId) return;
        const params = {
          "filters[sessionId]": SESSION_ID.BUOI_TRUA,
          "filters[date][$eq]": moment(selectedDate).format("YYYY-MM-DD"),
        };
        const attendanceEvent = await getAttendanceEvent(accountId, params);
        const ae = attendanceEvent.data?.data?.[0];
        setAttendanceEventData(ae);

        try {
          const paginationParams = {
            "pagination[pageSize]": pageSize.value,
            "pagination[page]": page,
          };
          const studentsByRoomResponse = await getAttendanceRoomId(
            accountId,
            roomId,
            paginationParams
          );
          if (studentsByRoomResponse?.status === 200) {
            const studentsByRoomData = studentsByRoomResponse?.data?.data;
            studentsByRoomData.forEach((element) => {
              element["boarding_attendance"] = true;
            });
            const params = {
              "filters[date]": moment(selectedDate).format("YYYY-MM-DD"),
              ...paginationParams,
            };
            const attendancesByRoomResponse = await getAttendanceByClassDate(
              accountId,
              roomId,
              params
            );
            attendancesByRoomResponse?.data?.data.forEach((element) => {
              const check = studentsByRoomData.filter((e) => {
                return (
                  e.id === element.students[0]?.id ||
                  e.id === element.students[0]
                );
              });
              if (check.length) {
                check[0]["boarding_attendance"] = element.boarding_attendance;
                check[0]["boarding_attendance_id"] = element.id;
              }
            });

            const attendanceParams = {
              "filters[date]": moment(selectedDate).format("YYYY-MM-DD"),
              "filters[boarding_attendance]": true,
              "pagination[pageSize]": 1,
            };
            const boardingAttendanceResponse = await getAttendanceByClassDate(
              accountId,
              roomId,
              attendanceParams
            );
            setTotalAttendanceStudents(
              boardingAttendanceResponse.data.pagination.total
            );

            setStudentList(studentsByRoomData);
            setTotalItems(studentsByRoomResponse.data.pagination.total);
          }
        } catch (err) {}
      } catch (error) {
      } finally {
        setTableLoading(false);
      }
    },
    [
      currentPage,
      roomId,
      selectedDate,
      accountId,
      setAttendanceEventData,
      pageSize.value,
    ]
  );

  const getRoom = useCallback(async () => {
    const res = await getRoomAttendance(accountId, userInfo?.user?.id);
    if (res?.status === 200) {
      const data = res?.data?.data;
      data.sort((a, b) => a.id - b.id);
      setRoomList(data);
      if (data?.length > 0) {
        setRoomId(data[0]?.id);
      }
    }
  }, [accountId, setRoomId, setRoomList, userInfo?.user?.id]);

  useEffect(() => {
    getRoom();
  }, [getRoom]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (isRefresh) {
      fetchData();
      setIsRefresh(false);
    }
  }, [fetchData, isRefresh, setIsRefresh]);

  return (
    <Inner
      data={studentList}
      tableLoading={tableLoading}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      pageSize={pageSize}
      setPageSize={setPageSize}
      totalItems={totalItems}
      setIsRefresh={setIsRefresh}
      totalAttendanceStudents={totalAttendanceStudents}
    />
  );
});

export default BoardingAttendance;
