import { Button } from "antd";
import { Notification } from "common/Notification";
import moment from "moment";
import { useAttendanceConext } from "page/diemDanh/Context";
import React, { memo, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getBoardingAttendanceCheck,
  handleAttendanceRawData,
  handleBoardingAttendance,
  updateAttendanceEvent,
} from "util/APIs";
import { routes } from "util/routes";
import styles from "../styles.module.css";

const SaveAllButton = memo(() => {
  const location = useLocation();
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;
  const {
    sectionId,
    selectedDate,
    roomList,
    attendanceEventData,
    setAttendanceEventData,
    setIsRefresh,
  } = useAttendanceConext();
  const [loading, setLoading] = useState(false);

  const handleAttendanceClass = useCallback(async () => {
    const url = `${location.pathname}${location.search}`;
    if (url !== routes.ATTENDANCE_BY_BOARDING) {
      //Set event
      const event_data = {
        data: {
          attendance: true,
        },
      };
      setLoading(true);
      const update_attendance_event = await updateAttendanceEvent(
        accountId,
        attendanceEventData?.id,
        event_data
      );
      if (update_attendance_event?.status === 200) {
        setAttendanceEventData((prev) => ({
          ...prev,
          attendance: update_attendance_event.data?.attendance,
        }));
      }
      const params = {
        data: {
          date: moment(selectedDate).format("YYYY-MM-DD"),
          sessionId: sectionId,
        },
      };
      try {
        const res = await handleAttendanceRawData(accountId, params);
        if (res?.status === 200) {
          setIsRefresh?.(true);
          Notification.sendSuccess("Đã lưu thành công.");
        }
      } catch (err) {
      } finally {
        setLoading(false);
      }
    } else {
      const params = {
        "filters[date][$eq]": moment(selectedDate).format("YYYY-MM-DD"),
        populate: ["classes"],
      };
      setLoading(true);
      try {
        const res = await getBoardingAttendanceCheck(accountId, params);
        if (res?.status === 200) {
          if (roomList.length === res?.data?.data.length) {
            //Set event
            const event_data = {
              data: {
                attendance: true,
              },
            };
            const update_attendance_event = await updateAttendanceEvent(
              accountId,
              attendanceEventData?.id,
              event_data
            );
            if (update_attendance_event?.status === 200) {
              setAttendanceEventData((prev) => ({
                ...prev,
                attendance: update_attendance_event.data?.attendance,
              }));
            }
            const params = {
              data: {
                date: moment(selectedDate).format("YYYY-MM-DD"),
              },
            };
            try {
              const res = await handleBoardingAttendance(accountId, params);
              if (res?.status === 200) {
                setIsRefresh?.(true);
                Notification.sendSuccess("Đã lưu thành công.");
              }
            } catch (err) {}
          } else {
            const rooms = roomList.map((item) => ({
              id: item.id,
              name: item.name,
            }));
            const data_lst = res.data?.data?.map((item) => item.classes?.[0]);
            const data = data_lst.map((item) => item.id);
            const elementsOnlyInArray1 = rooms.filter(
              (item) => !data.includes(item.id)
            );
            const result = [];
            elementsOnlyInArray1.forEach((element) => {
              result.push(element.name);
            });
            Notification.sendWarning(
              `${result.join(", ")} vẫn chưa lưu bán trú`
            );
          }
        }
      } catch (err) {
      } finally {
        setLoading(false);
      }
    }
  }, [
    accountId,
    attendanceEventData?.id,
    location.pathname,
    location.search,
    roomList,
    sectionId,
    selectedDate,
    setAttendanceEventData,
    setIsRefresh,
  ]);

  return (
    <>
      <Button
        className={`flex justify-center ${styles.searchBtn} ${
          attendanceEventData?.attendance ? styles.disableBtn : ""
        }`}
        disabled={attendanceEventData?.attendance ? true : false}
        onClick={loading ? null : handleAttendanceClass}
      >
        <span className={`${styles.searchBtnText}`}>Lưu tất cả</span>
      </Button>
    </>
  );
});

export default SaveAllButton;
