import { Button } from "antd";
import ExportReportModal from "page/diemDanh/modal/ExportReportModal";
import React, { memo, useState } from "react";
import styles from "../styles.module.css";

const ExportReportButton = memo(() => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Button
        onClick={handleOpenModal}
        className={`flex justify-center items-center ${styles.searchBtn}`}
      >
        <span className={`${styles.searchBtnText}`}>Xuất báo cáo</span>
      </Button>
      <ExportReportModal isModalOpen={isOpen} onCloseModal={handleOpenModal} />
    </>
  );
});

export default ExportReportButton;
