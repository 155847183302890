import React, { useCallback, useEffect } from "react";
import styles from "./styles.module.css";
import InfoFieldDatePicker from "common/CusInfoField/infoFieldDatePicker/InfoFieldDatePicker";
import moment from "moment";
import { useState } from "react";
import { useMemo } from "react";
import { TABLE_PAGE_SIZES, is1920OrMore } from "util/constant";
import { useSelector } from "react-redux";
import { createMenu, deleteMenu, getMenu, updateMenu, getLunchRegistry } from "util/APIs";
import CusTable from "common/CusTable";
import { Button, Form, Input, Modal, Space, Select } from "antd";
import { HEADER_TYPE } from "mobile/util/mobileConstant";
import { routes } from "util/routes";
import { closeIconThin, newEditIcon } from "asset/img/Images";
import { Notification } from "common/Notification";
import InfoFieldSelect from "common/CusInfoField/infoFieldSelect/InfoFieldSelect";
import { set } from "lodash";
import IsMobile from "hook/isMobile";
import MobileHeader from "mobile/layout/header/MobileHeader";
import CustomBreadcrumb from "common/breadcrumb/CustomBreadcrumb";
export default function LunchRegisterListPage() {
  const [form] = Form.useForm();
  const [data, setData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(moment().format());
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZES[0]);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [recordId, setRecordId] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);
  const [haveEdit, setHaveEdit] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);


  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;

  const handleSetSelectedDate = (value) => {
    const temp = moment(value).format();
    setSelectedDate(temp);
  };

  const responsive = {
    setResponsiveCol: () => {
      if (!is1920OrMore) {
        // columns[1].width = 120;
        // columns[2].width = 120;
        // columns[3].width = 120;
      }
    },
    nodeWrapperWidth: () => {
      if (is1920OrMore) return "560px";
      if (!is1920OrMore) return "560px";
    },
    tableScroll: () => {
      if (is1920OrMore) return {};
      else
        return {
          x: 1000,
          y: 490,
        };
    },
  };

  const columns = useMemo(() => {
    let columnLst = [
      {
        title: "STT",
        key: "STT",
        width: 50,
        align: "center",
        render: (value, record, index) =>
          (currentPage - 1) * pageSize.value + index + 1,
      },
      {
        title: "Học sinh",
        key: "foods",
        align: "center",
        dataIndex: "students",
        width: 100,
        render: (text, record) => <span>{text}</span>,
      },
      {
        title: "Món ăn",
        key: "foods",
        align: "center",
        dataIndex: "dishes",
        width: 100,
        render: (text, record) => <span>{text}</span>,
      },
      {
        title: "Trạng thái",
        key: "status",
        align: "center",
        dataIndex: "eaten",
        width: 100,
        render: (text, record) => {
          if (text === "true") {
            return <span>Đã ăn</span>;
          } else {
            return <span>Chưa ăn</span>;
          }
        },
      },
    ];
    return columnLst;
  }, [currentPage, form, pageSize.value]);

  const [dishes, setDishes] = useState(null);
  const [dishesList, setDishesList] = useState([]);

  const getDishes = useCallback(async () => {
    const params = {
      "filters[date][$eq]": moment(selectedDate).format("YYYY-MM-DD"),
      orderBy: "dishes",
    };
    const res = await getMenu(accountId, params);
    if (res?.status === 200) {
      const data = res?.data?.data.map((item) => ({
        id: item.id,
        name: item.dishes
      }));
      data.unshift({ id: 0, name: "Tất cả" })
      setDishesList(data);
      if (data?.length > 0) {
        setDishes(data[0]?.id);
      }
    }
  }, [accountId, selectedDate]);

  const getRegisterList = useCallback(async (page = null) => {
    if (accountId) {
      if (dishes === 0) {
        try {
          if (page === null) page = currentPage;
          setTableLoading(true);
          const params = {
            "pagination[pageSize]": pageSize.value,
            "pagination[page]": page,
            "filters[date][$eq]": moment(selectedDate).format("YYYY-MM-DD"),
          };
          const res = await getLunchRegistry(accountId, params);
          if (res?.status === 200) {
            // const register_list = data.flatMap(({ register_list, dishes }) => {
            //   if (register_list != [""])
            //     return register_list.map(item => ({ dishes: dishes, students: item.students, eaten: item.eaten }))
            // });
            const { data, pagination } = res?.data;
            setData(data);
            setTotalItems(pagination?.total);
            setCurrentPage(pagination?.page);
          }
        } catch (error) {
        } finally {
          setTableLoading(false);
        }
      }
      else {
        try {
          if (page === null) page = currentPage;
          setTableLoading(true);
          const params = {
            "pagination[pageSize]": pageSize.value,
            "pagination[page]": page,
            "filters[date][$eq]": moment(selectedDate).format("YYYY-MM-DD"),
            "filters[dishesId]": dishes,
          };
          const res = await getLunchRegistry(accountId, params);
          if (res?.status === 200) {
            const { data, pagination } = res?.data;
            setData(data);
            setTotalItems(pagination?.total);
            setCurrentPage(pagination?.page);
          }
        } catch (error) {
        } finally {
          setTableLoading(false);
        }
      }
    }
  }, [accountId, selectedDate, dishes, pageSize.value, currentPage]);






  useEffect(() => {
    getDishes();
  }, [getDishes]);

  useEffect(() => {
    getRegisterList();
  }, [getRegisterList]);

  // useEffect(() => {
  //   if (dishes) {
  //     getRegisterList();
  //   }
  // }, [getRegisterList]);
  const isUsingMobile = IsMobile();

  return (
    <div className={`flex flex-col h-full`}>
      {isUsingMobile ? (
        <MobileHeader
          type={HEADER_TYPE.middleTitle}
          title="Danh sách học sinh đăng ký"
          haveNotiButton={false}
        />
      ) : (
        <CustomBreadcrumb crumbs={[routes.CANTEEN_DETAIL]} />
      )}
      <div className={`py-4 section-shadow bg-white`}>
        <div className={`${styles.wrapper}`}>
          <h2 className={`${styles.header}`}>Danh sách HS đăng ký</h2>
          <div className={`${styles.wrapperFilters}`}>
            <InfoFieldDatePicker
              label={"Ngày"}
              value={selectedDate}
              setValue={handleSetSelectedDate}
              placeholder="Chọn thời gian"
              direction="vertical"
              className="flex-2"
              picker={"date"}
            />
            <InfoFieldSelect
              label="Món"
              data={dishesList}
              value={dishes}
              setValue={setDishes}
              direction="vertical"
              className="flex-2"
              // className={`flex-1 mr-4 max-[912px]:w-1/2`}
            />
          </div>
        </div>
        <div className={`mx-6 mt-5`}>
          <CusTable
            rowKey="id"
            data={data}
            havePagination={totalItems > pageSize.value}
            columns={columns}
            rowClassName="editable-row"
            page={currentPage}
            setPage={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            totalItems={totalItems}
            scroll={responsive.tableScroll()}
          />
        </div>
      </div>
    </div>
  );
}
