import GlobalLoading from "common/GlobalLoading";
import IsMobile from "hook/isMobile";
import Layout from "layout/Layout";
import MobileLayout from "mobile/layout/MobileLayout";
import MobileLoginPage from "mobile/page/loginPage";
import MenuPage from "mobile/page/menuPage";
import NotificationPage from "mobile/page/notificationPage";
import OAuthMobileLoginPage from "mobile/page/oauthLoginPage";
import OverviewPrimarySchoolPage from "mobile/page/overviewPrimarySchoolPage";
import ProfilePage from "mobile/page/profilePage";
import ResultPage from "mobile/page/resultPage";
import SchoolFeePage from "mobile/page/schoolFeePage";
import StudentScheduleMobilePage from "mobile/page/studentScheduleMobilePage";
import AccountPersonalPage from "page/accountPersonalPage";
import AdmissionsRouteConfigPage from "page/admissionsRouteConfigPage";
import AdmissionsSettingPage from "page/admissionsSettingPage";
import AppPrivacyPolicyPage from "page/appPrivacyPolicyPage";
import AreaStructurePage from "page/areaStructurePage";
import AssignStaffSchedulePage from "page/assignStaffSchedulePage";
import AttendancePage from "page/attendancePage";
import EvaluationPage from "page/evaluationPage";
import MessagePage from "page/messagePage";
import CapacityQualityPage from "page/capacityQualityPage";
import CertificateStudentPage from "page/certificateStudentPage";
import CollectRevenuePage from "page/collectRevenuePage";
import DailyTrackingMedicalPage from "page/dailyTrackingMedicalPage";
import DistributionSubjectPage from "page/distributionSubjectPage";
import EvaluateStructurePage from "page/evaluateStructurePage";
import GenerateTimetablePage from "page/generateTimetablePage";
import HealthBookPage from "page/healthBookPage";
import HomeworkInfoPage from "page/homeworkInfoPage";
import LoginPage from "page/loginPage";
import LocalLoginPage from "page/localLoginPage";
import LoginSSOCallbackPage from "page/loginSSOCallbackPage";
import MarkDetailPage from "page/markDetailPage";
import MarkPage from "page/markPage";
import OAuthLoginPage from "page/oauthLoginPage";
import OverviewPage from "page/overviewPage";
import PersonalEditPage from "page/personalEditPage";
import PersonalPage from "page/personalPage";
import ProfessionAssessmentCriteriaPage from "page/professionAssessmentCriteriaPage";
import ProfileReviewPage from "page/profileReviewPage";
import ReportCardDetailPage from "page/reportCardDetailPage";
import ReportCardPage from "page/reportCardPage";
import RevenueCategoryPage from "page/revenueCategoryPage";
import RevenueCatTypePage from "page/revenueCatTypePage";
import RevenueDetailPage from "page/revenueDetailPage";
import RevenuePage from "page/revenuePage";
import RoomMapPage from "page/roomMapPage";
import SchoolPage from "page/schoolPage";
import SchoolsPage from "page/schoolsPage";
import SearchPage from "page/searchPage";
import SettingPage from "page/settingPage";
import SettingTimetablePage from "page/settingTimetablePage";
import StaffSchedulePage from "page/staffSchedulePage";
import StudentDetailEditPage from "page/studentDetailEditPage";
import StudentManagementPage from "page/studentManagementPage";
import StudentTimetablePage from "page/studentTimetablePage";
import StudyInfoPage from "page/studyInfoPage";
import StudyProgramPage from "page/studyProgramPage";
import StudyResultPage from "page/studyResultPage";
import SubjectPage from "page/subjectPage";
import SubmittedHomeworkPage from "page/submittedHomeworkPage";
import SummaryDetailPage from "page/summaryDetailPage";
import SummaryDetailPsPage from "page/summaryDetailPsPage";
import SummaryPage from "page/summaryPage";
import TeacherDetailEditPage from "page/teacherDetailEditPage";
import TeacherDetailPage from "page/teacherDetailPage";
import TeacherManagementPage from "page/teacherManagementPage";
import TeachingGroupEditPage from "page/teachingGroupEditPage";
import TeachingGroupPage from "page/teachingGroupPage";
import TimetableListPage from "page/timetableListPage";
import TimetablePage from "page/timetablePage";
import TranscriptDetailPage from "page/transcriptDetailPage";
import TranscriptPage from "page/transcriptPage";
import UserPage from "page/userPage";
import ViolateManagementPage from "page/violateManagementPage";
import CustomRedirect from "page/CustomRedirect";
import SmsAdmin from "page/smsAdmin";
import GraderSupportPage from "page/graderSupportPage";
import React from "react";
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
    useHistory,
} from "react-router-dom";
import { OAUTH2_URL_FE } from "util/constant";
import { routes } from "util/routes";
import TeachingManagement from "page/teachingManagement";
import ImportButton from "common/ImportButton";
import StaffManageMentEditPage from "page/staffManagementEditPage";
import StudentPage from "mobile/page/studentPage";
import ParentCheckAttendance from "page/parentCheckAttendancePage";
import CanteenViewPage from "page/canteenViewPage";
import LunchRegisterPage from "page/lunchRegisterPage";
import LunchRegisterListPage from "page/lunchRegisterListPage";
import GradeBookPage from "../common/gradebookPage/index";
import ContactBook from "../page/contactBook/index";
import CanteenWeekPage from "../page/canteenWeekPage/index";
import AdminManagement from "../page/adminManagement/index";
import StudentLunchRegisterPage from "../page/studentLunchRegisterPage/index";
import ChangePassword from "../page/changePassword/index";
import DiemDanh from "../page/diemDanh/index";

export default function RouterProvider() {
    const isUsingMobile = IsMobile();

    const getSwitchAndRoutes = () => (
        <Switch>
            <Route exact path="/" render={() => <Redirect to="/login" />} />
            <Route
                exact
                path="/login"
                component={isUsingMobile ? MobileLoginPage : LoginPage}
            />
            <Route exact path="/local/login" component={LocalLoginPage} />
            <Route
                exact
                path="/api/v1/auth/google/callback"
                component={CustomRedirect}
            />
            <Route exact path={routes.CHANGE_PASSWORD} component={ChangePassword} />
            <Route
                exact
                path="/user/profile"
                component={isUsingMobile ? ProfilePage : TeacherDetailEditPage}
            />
            <Route
                exact
                path="/user/home"
                component={isUsingMobile ? MenuPage : TeacherDetailEditPage}
            />
            <Route
                exact
                path="/admin/staff-management"
                component={TeacherManagementPage}
            />
            <Route
                exact
                path="/admin/staff-management/edit/:staffId"
                component={isUsingMobile ? ProfilePage : TeacherDetailEditPage}
                // component={StaffManageMentEditPage}
            />
            <Route
                exact
                path="/admin/staff-management/add"
                component={TeacherDetailEditPage}
            />
            <Route
                exact
                // path="/admin/student-management"
                path={routes.STUDENT_INFO}
                component={StudentManagementPage}
            />
            <Route
                exact
                // path="/admin/student-management/edit"
                path={routes.ADMIN_STUDENT_MANAGEMENT_DETAIL}
                component={isUsingMobile ? StudentPage : StudentDetailEditPage}
                // component={StudentDetailEditPage}
            />
            <Route
                exact
                path="/admin/student-management/add"
                component={StudentDetailEditPage}
            />
            <Route
                exact
                path={routes.REGISTER_NOTEBOOKS}
                component={TeachingManagement}
            />
            <Route
                exact
                path="/canteen-management/lunch-register"
                component={LunchRegisterListPage}
            />
            <Route exact path="/admin/sms" component={SmsAdmin} />
            <Route exact path={routes.ATTENDANCE} component={DiemDanh} />
            <Route
                exact
                path={routes.CANTEEN_DETAIL}
                component={CanteenViewPage}
            />
            <Route
                exact
                path={routes.ADMIN_CANTEEN}
                component={AdminManagement}
            />
            <Route
                exact
                path={routes.PARENT_CHECK_ATTENDANCE}
                component={ParentCheckAttendance}
            />
            <Route
                exact
                path={routes.PARENT_CHECK_ATTENDANCE}
                component={ParentCheckAttendance}
            />
            <Route exact path={routes.EVALUATION} component={EvaluationPage} />
            <Route
                exact
                path={routes.CANTEEN_WEEK}
                component={CanteenWeekPage}
            />
            <Route
                exact
                path={routes.VIOLATE}
                component={ViolateManagementPage}
            />
            <Route exact path={routes.MESSAGE} component={MessagePage} />
            {/* <Route exact path={routes.LUNCH_REGISTRY} component={LunchRegisterPage} /> */}
            <Route
                exact
                path={routes.LUNCH_REGISTRY}
                component={StudentLunchRegisterPage}
            />
            <Route
                exact
                path={routes.TEACHING_DISTRIBUTION}
                component={AssignStaffSchedulePage}
            />
            <Route exact path={routes.CONTACT_BOOK} component={ContactBook} />
            <Route
                exact
                path={routes.GRADER_SUPPORT}
                component={GraderSupportPage}
            />
            <Route
                exact
                path={routes.TIMETABLE}
                component={TimetableListPage}
            />
            <Route
                exact
                path={routes.TRANSCRIPT}
                component={TranscriptDetailPage}
            />
            <Route exact path={routes.GRADEBOOK} component={GradeBookPage} />
            {/* <Route
  const getSwitchAndRoutes = () => (
    <Switch>
      <Route exact path="/" render={() => <Redirect to="/login" />} />
      <Route
        exact
        path="/login"
        component={isUsingMobile ? MobileLoginPage : LoginPage}
      />
      <Route exact path="/local/login" component={LocalLoginPage} />
      <Route
        exact
        path="/api/v1/auth/google/callback"
        component={CustomRedirect}
      />
      <Route exact path={routes.CHANGE_PASSWORD} component={ChangePassword} />
      <Route
        exact
        path="/user/profile"
        component={isUsingMobile ? ProfilePage : TeacherDetailEditPage}
      />
      <Route
        exact
        path="/user/home"
        component={isUsingMobile ? MenuPage : TeacherDetailEditPage}
      />
      <Route
        exact
        path="/admin/staff-management"
        component={TeacherManagementPage}
      />
      <Route
        exact
        path="/admin/staff-management/edit/:staffId"
        component={isUsingMobile ? ProfilePage : TeacherDetailEditPage}
        // component={StaffManageMentEditPage}
      />
      <Route
        exact
        path="/admin/staff-management/add"
        component={TeacherDetailEditPage}
      />
      <Route
        exact
        // path="/admin/student-management"
        path={routes.STUDENT_INFO}
        component={StudentManagementPage}
      />
      <Route
        exact
        // path="/admin/student-management/edit"
        path={routes.ADMIN_STUDENT_MANAGEMENT_DETAIL}
        component={isUsingMobile ? StudentPage : StudentDetailEditPage}
        // component={StudentDetailEditPage}
      />
      <Route
        exact
        path="/admin/student-management/add"
        component={StudentDetailEditPage}
      />
      <Route
        exact
        path={routes.REGISTER_NOTEBOOKS}
        component={TeachingManagement}
      />
      <Route
        exact
        path="/canteen-management/lunch-register"
        component={LunchRegisterListPage}
      />
      <Route exact path="/admin/sms" component={SmsAdmin} />
      <Route exact path={routes.ATTENDANCE} component={AttendancePage} />
      <Route exact path={routes.CANTEENT_DETAIL} component={CanteenViewPage} />
      <Route exact path={routes.ADMIN_CANTEEN} component={AdminManagement} />
      <Route
        exact
        path={routes.PARENT_CHECK_ATTENDANCE}
        component={ParentCheckAttendance}
      />
      <Route
        exact
        path={routes.PARENT_CHECK_ATTENDANCE}
        component={ParentCheckAttendance}
      />
      <Route exact path={routes.EVALUATION} component={EvaluationPage} />
      <Route exact path={routes.CANTEEN_WEEK} component={CanteenWeekPage} />
      <Route exact path={routes.VIOLATE} component={ViolateManagementPage} />
      <Route exact path={routes.MESSAGE} component={MessagePage} />
      <Route exact path={routes.LUNCH_REGISTRY} component={LunchRegisterPage} />
      <Route
        exact
        path={routes.TEACHING_DISTRIBUTION}
        component={AssignStaffSchedulePage}
      />
      <Route exact path={routes.CONTACT_BOOK} component={ContactBook} />
      <Route exact path={routes.GRADER_SUPPORT} component={GraderSupportPage} />
      <Route exact path={routes.TIMETABLE} component={TimetableListPage} />
      <Route exact path={routes.TRANSCRIPT} component={TranscriptDetailPage} />
      <Route exact path={routes.GRADEBOOK} component={GradeBookPage} />
      {/* <Route
        exact
        path={OAUTH2_URL_FE}
        component={isUsingMobile ? OAuthMobileLoginPage : OAuthLoginPage}
      /> */}
            {/* <Route exact path="/home" component={OverviewPage} /> */}
            {/* <Route exact path="/search" component={SearchPage} /> */}
            {/* Quản lý trường học */}
            {/* <Route exact path="/school" component={SchoolPage} /> */}
            {/* Quản lý giảng dạy */}
            {/* <Route exact path="/curriculum" component={StudyProgramPage} />
      <Route
        exact
        path="/curriculum-distribution"
        component={DistributionSubjectPage}
      />
      <Route
        exact
        path="/teaching-distribution"
        component={AssignStaffSchedulePage}
      />
      <Route exact path="/subject" component={SubjectPage} />
      <Route exact path="/timetable-information" component={TimetablePage} />
      <Route exact path="/teaching-schedule" component={StaffSchedulePage} />
      <Route exact path="/homework" component={MarkPage} />
      <Route exact path="/mark-detail" component={MarkDetailPage} />
      <Route
        exact
        path="/submitted-homework"
        component={SubmittedHomeworkPage}
      />
      <Route
        exact
        path="/evaluate-structure"
        component={EvaluateStructurePage}
      />
      <Route
        exact
        path="/timetable-auto-gen"
        component={GenerateTimetablePage}
      />
      <Route exact path="/timetable-setting" component={SettingTimetablePage} /> */}
            {/* Quản lý học sinh */}
            {/* <Route
        exact
        path="/student-management"
        component={StudentManagementPage}
      />
      <Route exact path="/personal" component={PersonalPage} />
      <Route exact path="/personal/edit" component={PersonalEditPage} />
      <Route exact path="/search-school-record" component={ReportCardPage} />
      <Route
        exact
        path="/search-school-record-detail"
        component={ReportCardDetailPage}
      />
      <Route exact path="/attendance" component={AttendancePage} />
      <Route exact path="/transcript" component={TranscriptPage} />
      <Route exact path="/transcript-detail" component={TranscriptDetailPage} />
      <Route exact path="/capacity-quality" component={CapacityQualityPage} />
      <Route exact path="/study-summary" component={SummaryPage} />
      <Route exact path="/study-summary-detail" component={SummaryDetailPage} />
      <Route
        exact
        path="/study-summary-detail-ps"
        component={SummaryDetailPsPage}
      /> */}
            {/* Quản lý nhân sự */}
            {/* <Route exact path="/teacher-detail" component={TeacherDetailPage} />
      <Route
        exact
        path="/teacher-detail/edit"
        component={TeacherDetailEditPage}
      />
      <Route exact path="/teaching-group" component={TeachingGroupPage} />
      <Route
        exact
        path="/teaching-group/edit"
        component={TeachingGroupEditPage}
      /> */}
            {/* Quản lý tài khoản */}
            {/* <Route exact path="/user-management" component={UserPage} /> */}
            {/* Quản lý khen thưởng */}
            {/* <Route
        exact
        path="/certificate-of-merit"
        component={CertificateStudentPage}
      /> */}
            timetable-information
            {/* Quản lý khoản thu */}
            {/* <Route exact path="/receipt-management" component={RevenuePage} />
      <Route exact path="/revenue-detail" component={RevenueDetailPage} />
      <Route exact path="/collect-revenue" component={CollectRevenuePage} />
      <Route exact path="/receipt-item" component={RevenueCategoryPage} />
      <Route exact path="/category" component={RevenueCatTypePage} /> */}
            {/* Quản lý cơ sở vật chất */}
            {/* <Route exact path="/area-structure" component={AreaStructurePage} />
      <Route exact path="/room-map" component={RoomMapPage} /> */}
            {/* Thiết lập hệ thống */}
            {/* <Route exact path="/setting" component={SettingPage} /> */}
            {/* Cổng thông tin */}
            {/* <Route exact path="/learning-information" component={StudyInfoPage} />
      <Route exact path="/student/edit" component={StudentEditPage} />
      <Route exact path="/learning-outcomes" component={StudyResultPage} />
      <Route
        exact
        path="/student-schedule"
        component={
          isUsingMobile ? StudentScheduleMobilePage : StudentTimetablePage
        }
      />
      <Route exact path="/homework-info" component={HomeworkInfoPage} /> */}
            {/* Webapp */}
            {/* <Route exact path="/result" component={ResultPage} />
      <Route exact path="/school-fee" component={SchoolFeePage} />
      <Route exact path="/menu" component={MenuPage} />
      <Route exact path="/notification" component={NotificationPage} /> */}
            {/* Thông tin cá nhân */}
            {/* <Route exact path="/personal-page" component={AccountPersonalPage} /> */}
            {/* Y tế */}
            {/* <Route
        exact
        path="/daily-tracking-medical"
        component={DailyTrackingMedicalPage}
      />
      <Route exact path="/periodic-health-check" component={HealthBookPage} />
      <Route exact path="/health-book" component={HealthBookPage} /> */}
            {/* Danh sách trường */}
            {/* <Route exact path="/schools" component={SchoolsPage} />
      <Route
        exact
        path="/profession-assessment-criteria"
        component={ProfessionAssessmentCriteriaPage}
      />
      <Route exact path="/timetable-list" component={TimetableListPage} /> */}
            {/* Tuyển sinh */}
            {/* <Route
        exact
        path="/admissions-setting"
        component={AdmissionsSettingPage}
      />
      <Route
        exact
        path="/admissions-route-config"
        component={AdmissionsRouteConfigPage}
      />
      <Route exact path="/profile-review" component={ProfileReviewPage} />
      {/* Tổng quan Phòng Sở */}
            {/* <Route
        exact
        path="/overview-primary-school"
        component={OverviewPrimarySchoolPage}
      /> */}
            {/* Other routes */}
            {/* <Route
        exact
        path="/app-privacy-policy"
        component={AppPrivacyPolicyPage}
      />
      <Route
        exact
        path="/login-sso-callback"
        component={LoginSSOCallbackPage}
      /> */}
        </Switch>
    );

    return (
        <Router>
            <GlobalLoading />
            {isUsingMobile ? (
                <MobileLayout>{getSwitchAndRoutes()}</MobileLayout>
            ) : (
                <Layout>{getSwitchAndRoutes()}</Layout>
            )}
        </Router>
    );
}
