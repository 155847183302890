import NoDataBox from "common/noDataBox/NoDataBox";
import SpinLoading from "common/spinLoading";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { lunchRegistryService } from "services/lunchRegistryService";
import { formatDateValue } from "util/common";
import styles from "../styles.module.css";

const dayOfWeeks = [
    "Chủ nhật",
    "Thứ hai",
    "Thứ ba",
    "Thứ tư",
    "Thứ năm",
    "Thứ sáu",
    "Thứ bảy",
];

const MenuListRegistered = memo(({ startDateWeek, endDateWeek }) => {
    const userInfo = useSelector((state) => state?.app?.userInfo);
    const accountId = userInfo?.account?.id;
    const studentId = userInfo?.user?.id;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const getMenuListByWeek = useCallback(async () => {
        setLoading(true);
        const params = {
            orderBy: "date",
            "filters[date][$gte]": startDateWeek,
            "filters[date][$lte]": endDateWeek,
            "filters[status]": "no_lunch",
            "filters[studentId]": studentId,
        };
        const response = await lunchRegistryService.getMenuListByWeek(
            accountId,
            params
        );
        if (response?.status === 200) {
            setData(response.data.data);
        }
        setLoading(false);
    }, [accountId, endDateWeek, startDateWeek, studentId]);

    useEffect(() => {
        getMenuListByWeek();
    }, [getMenuListByWeek]);

    return (
        <>
            {loading ? (
                <SpinLoading isLoading={loading} />
            ) : (
                <>
                    {data.length === 0 ? (
                        <NoDataBox />
                    ) : (
                        <table className={styles.tableWrapper}>
                            <thead>
                                <tr>
                                    <th className={styles.thWrapper}>Thứ</th>
                                    <th className={styles.thWrapper}>Ngày</th>
                                    <th className={styles.thWrapper}>
                                        Món đã chọn
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, index) => (
                                    <tr key={item.id}>
                                        <td className={styles.tdWrapper}>
                                            {dayOfWeeks[index]}
                                        </td>
                                        <td className={styles.tdWrapper}>
                                            {formatDateValue(item.date)}
                                        </td>
                                        <td className={styles.tdWrapper}>
                                            {item.dishes}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </>
            )}
        </>
    );
});

export default MenuListRegistered;
