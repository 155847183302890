import axios from "axios";
import { Notification } from "common/Notification";
import { errorConstant } from "util/errorConstant";
import { routes } from "util/routes";
import { LOGIN_URL_FE, OAUTH2_URL_FE } from "./constant";
import { logMessage } from "./helper";

const getToken = () => {
  return localStorage.getItem("token");
};

const redirectToLogin = () => {
  localStorage.clear();
  setTimeout(() => {
    window.location.href = routes.LOGIN;
  }, 0);
};

const handleBadRequest = (data) => {
  const message = data?.error?.message;
  let text = "";
  switch (message) {
    case errorConstant.INVALID_PASSWORD:
      text = "Mật khẩu hiện tại không hợp lệ.";
      break;
    case errorConstant.INVALID_IDENTIFIER_OR_PASSWORD:
      text = "Tên đăng nhập hoặc mật khẩu không hợp lệ";
      break;
    default:
      text = message;
  }
  Notification.sendError(text);
};

const buildAxiosConfig = (api, method, headers = null) => {
  let config = {
    url: api,
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  if (headers) config.headers = { ...config.headers, ...headers };
  const token = getToken();
  if (token) {
    config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
  } else {
    localStorage.clear();
  }
  return config;
};

const callAxios = (
  api,
  payload = null,
  method,
  headers = null,
  isShowErrorMessage
) => {
  const config = buildAxiosConfig(api, method, headers);
  if (payload) {
    if (method === "get") config.params = payload;
    else config.data = payload;
  }
  return axios(config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      const status = err.response?.status;
      const data = err.response?.data;
      const token = getToken();
      // Nếu status trả ra là 401 và không phải đang ở trang login hoặc oauth2 thì logout
      if (
        status === 401 &&
        !window.location.href?.includes(LOGIN_URL_FE) &&
        !window.location.href?.includes(OAUTH2_URL_FE)
      ) {
        // logout();
        // Notification.sendError(
        //   "Phiên đăng nhập của bạn đã hết hạn. Vui lòng đăng nhập lại."
        // );
        redirectToLogin();
      } else if (status === 403 && !token) {
        redirectToLogin();
      } else if (status === 400) {
        handleBadRequest(data);
        return {
          api,
          status,
          data,
        };
      } else {
        if (isShowErrorMessage) {
          logMessage(err);
        }
        return {
          api,
          status,
          data,
        };
      }
    });
};

export const get = (
  api,
  params = null,
  headers = null,
  isShowErrorMessage = true
) => {
  return callAxios(api, params, "get", headers, isShowErrorMessage);
};

export const post = (
  api,
  body = null,
  headers = null,
  isShowErrorMessage = true
) => {
  return callAxios(api, body, "post", headers, isShowErrorMessage);
};

export const put = (
  api,
  body = null,
  headers = null,
  isShowErrorMessage = true
) => {
  return callAxios(api, body, "put", headers, isShowErrorMessage);
};

export const patch = (
  api,
  body = null,
  headers = null,
  isShowErrorMessage = true
) => {
  return callAxios(api, body, "patch", headers, isShowErrorMessage);
};

export const remove = (
  api,
  body = null,
  headers = null,
  isShowErrorMessage = true
) => {
  return callAxios(api, body, "delete", headers, isShowErrorMessage);
};

export const logout = (history) => {
  localStorage.clear();
  history.push(routes.LOGIN);
  // window.location.href = "/login";
};
