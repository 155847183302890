import CusTable from "common/CusTable";
import IsMobile from "hook/isMobile";
import moment from "moment";
import { useAttendanceConext } from "page/diemDanh/Context";
import Filter from "page/diemDanh/component/Filter";
import SelectAttendance from "page/diemDanh/component/SelectAttendance";
import AttendanceStatus from "page/diemDanh/status/AttendanceStatus";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { formatDateValue } from "util/common";
import styles from "../../styles.module.css";

const Inner = memo(
  ({
    data,
    tableLoading,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    totalItems,
  }) => {
    const { selectedDate, classId, sectionId, lessonId } =
      useAttendanceConext();
    const [monthColumns, setMonthColumns] = useState([]);
    const isUsingMobile = IsMobile();

    const columns = useMemo(() => {
      let columnLst = [
        {
          title: "STT",
          key: "STT",
          width: 50,
          align: "center",
          fixed: "center",
          render: (value, record, index) =>
            (currentPage - 1) * pageSize.value + index + 1,
        },
        {
          title: "Họ",
          align: "center",
          width: 200,
          key: `last_name`,
          dataIndex: "last_name",
          // fixed: "center",
          render: (text, record) => <span>{text}</span>,
        },
        {
          title: "Tên",
          align: "center",
          width: 100,
          key: `first_name`,
          dataIndex: "first_name",
          // fixed: "center",
          render: (text, record) => <span>{text}</span>,
          className: styles.fixedCol,
        },
        {
          title: "Mã học sinh",
          width: 150,
          align: "center",
          dataIndex: "moeat_id",
          render: (text, record) => <span>{text}</span>,
        },
        {
          title: "Ngày sinh",
          dataIndex: "dob",
          width: 150,
          align: "center",
          render: (value) => <span>{formatDateValue(value)}</span>,
        },
      ];
      if (isUsingMobile) {
        columnLst = columnLst.filter((item) => item.dataIndex !== "dob");
      }
      return columnLst;
    }, [currentPage, isUsingMobile, pageSize.value]);

    const getDaysInMonth = (month, year) => {
      const date = new Date(year, month, 1);
      const days = [];
      while (date.getMonth() === month) {
        const _date = new Date(date);
        days.push({
          date: moment(_date).format(),
          dayOfMonth: _date.getDate(),
          dayOfWeek: _date.getDay(),
        });
        date.setDate(date.getDate() + 1);
      }
      return days;
    };

    const setupMonthColumns = useCallback(() => {
      const dt = new Date(selectedDate);
      const year = dt.getFullYear();

      let daysInMonth = getDaysInMonth(dt.getMonth(), year);

      const children = daysInMonth.map((val) => {
        let dateString =
          val.dayOfMonth < 10 ? `0${val.dayOfMonth}` : val.dayOfMonth;
        const format = moment(val.date).format("YYYY-MM-DD");
        return {
          title: <span>{dateString}</span>,
          key: `date${val.dayOfMonth}`,
          dataIndex: "attendances",
          editable: true,
          align: "center",
          width: 90,
          render: (value, record) => {
            const foundItem = value?.find?.(
              (item) =>
                item?.date === format &&
                item?.lessons?.[0]?.id === lessonId &&
                item?.sessions?.[0]?.id === sectionId
            );
            return {
              props: {
                style: {
                  background: val.dayOfWeek === 0 ? "#F7EAEA" : "inherit",
                },
              },
              children: (
                <div className="flex justify-center items-center">
                  <SelectAttendance
                    attendanceId={foundItem?.id ?? null}
                    studentId={record?.id}
                    date={val.date}
                    attendanceStatus={foundItem?.workflow_state ?? null}
                  />
                </div>
              ),
            };
          },
        };
      });

      setMonthColumns([...columns, ...children]);
    }, [columns, lessonId, sectionId, selectedDate]);

    useEffect(() => {
      if (classId && selectedDate) {
        setupMonthColumns();
      }
    }, [classId, selectedDate, setupMonthColumns]);

    return (
      <div className="px-8">
        <Filter />
        <AttendanceStatus />

        <CusTable
          rowKey="id"
          data={data}
          columns={monthColumns}
          loading={tableLoading}
          havePagination={totalItems > pageSize.value}
          page={currentPage}
          setPage={setCurrentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalItems={totalItems}
          scroll={{ x: "1000px", y: 480 }}
          rowClassName="bg-white attendance-row"
        />
      </div>
    );
  }
);

export default Inner;
