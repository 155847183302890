import { Spin, Button, DatePicker, Form, Input, Modal, Select, Space } from "antd";
import { newEditIcon, uploadIcon } from "asset/img/Images";
import InfoFieldDatePicker from "common/CusInfoField/infoFieldDatePicker/InfoFieldDatePicker";
import InfoFieldSelect from "common/CusInfoField/infoFieldSelect/InfoFieldSelect";
import CusTable from "common/CusTable";
import CustomBreadcrumb from "common/breadcrumb/CustomBreadcrumb";
import IsMobile from "hook/isMobile";
import _ from "lodash";
import MobileHeader from "mobile/layout/header/MobileHeader";
import { HEADER_TYPE } from "mobile/util/mobileConstant";
import moment from "moment";
import styles from "page/attendancePage/component/styles.module.css";
import innerstyles from "./styles.module.css";

import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getAttendanceEvaluationList,
  getClassListByGrade,
  getClassList,
  getGradeList,
  getRuleList,
  getSessionList,
  getStudentViolateRules,
  exportStudentViolate,
  getAttendanceByDay,
  createStudentViolateRules,
  updateStudentViolateRules,
} from "util/APIs";
import { DATE_FORMAT, TABLE_PAGE_SIZES, is1920OrMore } from "util/constant";
import { STUDENT_MANAGEMENT, routes } from "util/routes";
import EvaluationField from "./component/EvaluationField";
import violateStyles from "page/violateManagementPage/styles.module.css";
import evaluationStyles from "./styles.module.css";
import FileList, { FileItem } from "common/FilePreviewer/FileList";
import IconFile from "asset/icons/IconFile";
import FilePicker from "common/filePicker/FilePicker";
import FileShower from "common/filePicker/component/FileShower";
import { Notification } from "common/Notification";
import { rule } from "postcss";

const { Option } = Select;

const FORMAT_DATE = "YYYY-MM-DD";

export default function ViolateManagementPage() {
  const isUsingMobile = IsMobile();
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;

  const isAllowToUpdate = true;

  const responsive = {
    setResponsiveCol: () => {
      if (!is1920OrMore) {
        columns[1].width = 120;
        columns[2].width = 120;
        columns[3].width = 120;
      }
    },
    nodeWrapperWidth: () => {
      if (is1920OrMore) return "560px";
      if (!is1920OrMore) return "560px";
    },
    tableScroll: () => {
      if (is1920OrMore) return {};
      else
        return {
          x: 1000,
          y: 490,
        };
    },
  };
  const [form] = Form.useForm();

  const schoolYear = useSelector(
    // (state) => state.schoolYear.selectedSchoolYear
    (state) => state
  );

  const [grade, setGrade] = useState(null);
  const [section, setSection] = useState(null);
  const [classId, setClassId] = useState(null);
  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZES[0]);
  const [gradeList, setGradeList] = useState([]);
  const [classList, setClassList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [editingKey, setEditingKey] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isRefresh, setIsRefresh] = useState(false);
  const [sectionList, setSectionList] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);
  const [violateData, setViolateData] = useState([]);
  const [selectedDateFrom, setSelectedDateFrom] = useState(moment().format());
  const [selectedDateTo, setSelectedDateTo] = useState(moment().format());
  const [dateError, setDateError] = useState(false);

  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [selectedExportDateFrom, setSelectedExportDateFrom] = useState(moment().format());
  const [selectedExportDateTo, setSelectedExportDateTo] = useState(moment().format());


  const [ruleList, setRuleList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadData, setLoadData] = useState(false);

  const viewFile = (values) => {
    const lst = values ? JSON.parse(values) : [];
    if (lst?.length) {
      const formattedFiles = lst?.map?.((item) => ({
        name: item.title,
        url: item.url,
        type: item.mimetype,
      }));
      return (
        <FileList attachments={formattedFiles}>
          <FileItem {...formattedFiles[0]}>
            {({ onOpen }) => {
              return (
                <div onClick={onOpen}>
                  <div className={`${evaluationStyles.viewFile}`}>
                    <IconFile />
                  </div>
                </div>
              );
            }}
          </FileItem>
        </FileList>
      );
    }
  };

  const columns = [
    {
      title: "STT",
      align: "center",
      key: "STT",
      render: (value, record, index) =>
        (currentPage - 1) * pageSize.value + index + 1,
      width: "10%",
      fixed: "left",
    },
    // {
    //   title: "Học sinh",
    //   align: "center",
    //   key: "Students",
    //   dataIndex: "students",
    //   render: (text) => <span>{text}</span>,
    //   width: "10%",
    // },
    {
      title: "Học sinh",
      align: "left",
      key: "students",
      dataIndex: ["students", 0, "full_name"],
      render: (text) => <span>{text}</span>,
      width: "15%",
    },
    {
      title: "Lớp",
      align: "center",
      key: "classes",
      dataIndex: ["classes", 0, "name"],
      render: (text) => <span>{text}</span>,
      width: "10%",
    },
    // {
    //   title: "Mã học sinh",
    //   dataIndex: ["students", 0, "moeat_id"],
    //   editable: false,
    //   align: "center",
    //   render: (text) => <span>{text}</span>,
    //   width: "15%",
    // },

    {
      title: "Vi phạm",
      key: "rules",
      align: "center",
      width: "40%",
      render: (value, record) => {
        const editable = isEditing(record);
        const values = record?.rules?.map((item) => item.id);
        return (
          <EvaluationField
            values={values}
            options={ruleList}
            disabled={!editable || !isAllowToUpdate}
            record={record}
            accountId={accountId}
            setIsRefresh={setIsRefresh}
          />
        );
      },
    },
    {
      title: "Ngày vi phạm",
      align: "center",
      key: "date",
      dataIndex: "date",
      render: (text) => <span>{text}</span>,
      width: "15%",
    },
    {
      title: "Files",
      align: "center",
      dataIndex: "files",
      render: (text, record) => viewFile(text),
      width: "10%",
    },
    // {
    //   title: "",
    //   dataIndex: "id",
    //   align: "center",
    //   render: (_, record) => (
    //     <Space size={2}>
    //       <Button
    //         type="link"
    //         disabled={!isAllowToUpdate}
    //         onClick={() => edit(record)}
    //       >
    //         <img
    //           src={newEditIcon}
    //           alt="Edit"
    //           className="editIcon" // Ensure the CSS class is correctly defined
    //         />
    //       </Button>
    //     </Space>
    //   ),
    //   width: "10%",
    // },
  ];
  // responsive.setResponsiveCol();

  const getGrade = useCallback(async () => {
    const res = await getGradeList(accountId);
    if (res?.status === 200) {
      const data = res?.data?.data;
      data.unshift({ id: 4, name: "Tất cả" });
      data.pop()
      setGradeList(data);
      if (data?.length > 0) {
        setGrade(data[0]?.id);
      }
    }
  }, [accountId]);

  const getClass = useCallback(
    async (gradeId) => {
      if (gradeId === 4) {
        const params = {
          skip_joins: true,
        };
        const res = await getClassList(accountId, params);
        if (res?.status === 200) {
          const data = res?.data?.data;
          data.unshift({ id: 0, name: "Tất cả" });
          setClassList(data);

          if (data?.length > 0) {
            setClassId(data[0]?.id);
          }
        }
      } else {
        const res = await getClassListByGrade(accountId, gradeId);
        if (res?.status === 200) {
          const data = res?.data?.data;
          setClassList(data);

          if (data?.length > 0) {
            setClassId(data[0]?.id);
          }
        }
      }
    },
    [accountId]
  );

  const getRules = useCallback(async () => {
    const res = await getRuleList(accountId);
    if (res?.status === 200) {
      const data = res?.data?.data;
      const options = data?.map((item) => ({
        category: item?.Category,
        label: item?.title,
        value: item?.id,
      }));

      const formatOptions = _.chain(options)
        // Group the elements of Array based on `cateogry` property
        .groupBy("category")
        // `key` is group's name (cateogry), `value` is the array of objects
        .map((value, key) => ({ label: key, options: value }))
        .value();
      setRuleList(formatOptions);
    }
  }, [accountId]);

  const getSectionList = useCallback(async () => {
    // const res = await getSetting(SETTING_GROUP_KEYS.section, setSectionList);
    // if (res?.data?.data?.[0]) {
    //   setSection(res?.data?.data?.[0]?.key);
    // }
    const params = {
      // orderBy: "title",
    };
    const res = await getSessionList(accountId, params);
    if (res?.status === 200) {
      const data = res?.data?.data;
      setSectionList(res?.data?.data);
      if (data?.length > 0) {
        // setSection(data[0]?.session_code);
        setSection(data[0]?.id);
      }
    }
  }, [accountId]);

  const getData = useCallback(
    async (page = null) => {
      try {
        if (page === null) page = currentPage;
        setTableLoading(true);
        if (classId !== 0) {
          if (
            moment(selectedDateFrom).format(FORMAT_DATE) >
            moment(selectedDateTo).format(FORMAT_DATE)
          ) {
            setDateError(true);
          } else {
            setDateError(false);
          }
          const params = {
            "pagination[pageSize]": pageSize.value,
            "pagination[page]": page,
            "filters[classes.id]": classId,
            "filters[date][$gte]": moment(selectedDateFrom).format(FORMAT_DATE),
            "filters[date][$lte]": moment(selectedDateTo).format(FORMAT_DATE),
            "filters[sessions.id]": section
          };
          const res = await getStudentViolateRules(accountId, params);
          if (res?.status === 200) {
            const { data, pagination } = res?.data;
            setViolateData(res?.data?.data);
            // setTotalItems(res?.data?.pagination?.total);
            // setCurrentPage(res?.data?.pagination?.page);
          }
        } else {
          if (
            moment(selectedDateFrom).format(FORMAT_DATE) >
            moment(selectedDateTo).format(FORMAT_DATE)
          ) {
            setDateError(true);
          } else {
            setDateError(false);
          }
          const params = {
            "pagination[pageSize]": pageSize.value,
            "pagination[page]": page,
            "filters[date][$gte]": moment(selectedDateFrom).format(FORMAT_DATE),
            "filters[date][$lte]": moment(selectedDateTo).format(FORMAT_DATE),
            "filters[sessions.id]": section
          };
          const res = await getStudentViolateRules(accountId, params);
          if (res?.status === 200) {
            const data = res?.data?.data.sort(
              (a, b) => b.rules.length - a.rules.length
            );

            setViolateData(data);
            // setTotalItems(res?.data?.pagination?.total);
            // setCurrentPage(res?.data?.pagination?.page);
          }
        }
      } catch (error) {
      } finally {
        setTableLoading(false);
      }
    },
    [
      currentPage,
      classId,
      pageSize.value,
      selectedDateFrom,
      selectedDateTo,
      accountId,
    ]
  );

  const isEditing = (record) => record?.id === editingKey;

  const edit = (record) => {
    if (editingKey === record?.id) {
      setEditingKey("");
    } else {
      setEditingKey(record?.id);
    }
  };

  const handleSelectDateFrom = (value) => {
    const temp = moment(value).format();
    setSelectedDateFrom(temp);
  };
  const handleSelectDateTo = (value) => {
    const temp = moment(value).format();
    setSelectedDateTo(temp);
  };

  const handleSelectExportDateFrom = (value) => {
    const temp = moment(value).format();
    setSelectedExportDateFrom(temp);
  };
  const handleSelectExportDateTo = (value) => {
    const temp = moment(value).format();
    setSelectedExportDateTo(temp);
  };

  useEffect(() => {
    if (schoolYear && schoolYear !== null) getGrade();
  }, [schoolYear, getGrade]);

  useEffect(() => {
    if (grade && grade !== null) {
      getClass(grade);
    }
    getSectionList();
  }, [grade, getClass, getSectionList]);

  useEffect(() => {
    getData();
    getRules();
  }, [classId, selectedDateFrom, selectedDateTo, section, getData, getRules]);

  useEffect(() => {
    if (isRefresh) {
      getData();
      setIsRefresh(false);
    }
  }, [getData, isRefresh]);

  const showExportModal = () => {
    setIsExportModalOpen(true);
  };
  const handleExportOk = async () => {

    try {
      setLoading(true); // Set loading to true before making the API call

      const params = {
        "filters[date][$gte]": selectedExportDateFrom,
        "filters[date][$lte]": selectedExportDateTo,
      };

      const res = await exportStudentViolate(accountId, params);
      if (res.status === 200) {
        window.open(res.data.data, '_blank');
      }
    } catch (error) {
      console.error('Lỗi xuất dữ liệu:', error);
    } finally {
      setLoading(false); // Set loading to false after the API call completes (whether success or error)
      setIsExportModalOpen(false);
    }
  };
  const handleExportCancel = () => {
    setIsExportModalOpen(false);
  };


  return (
    <div className={`flex flex-col h-full`}>
      {isUsingMobile ? (
        <MobileHeader
          type={HEADER_TYPE.middleTitle}
          title="Vi phạm"
          haveNotiButton={false}
        />
      ) : (
        <CustomBreadcrumb
          crumbs={[STUDENT_MANAGEMENT, routes.EVALUATION]}
          style={styles.breadcrumb}
        />
      )}
      <div className={`py-4 section-shadow bg-white`}>
        <div className={`pt-5`}>
          <div className={`${innerstyles.wrapperHeader}`}>
            <div className={`${violateStyles.wrapperFilters}`}>
              <InfoFieldSelect
                label="Khối"
                data={gradeList}
                value={grade}
                setValue={setGrade}
                direction="vertical"
                className="flex-1"
              />

              <InfoFieldSelect
                label="Lớp"
                data={classList}
                value={classId}
                setValue={setClassId}
                direction="vertical"
                className="flex-1"
              />

              <InfoFieldDatePicker
                label={"Từ Ngày"}
                value={selectedDateFrom}
                setValue={handleSelectDateFrom}
                placeholder="Chọn thời gian"
                direction="vertical"
                className="flex-1"
                picker={"date"}
              />
              <InfoFieldDatePicker
                label={"Tới ngày"}
                value={selectedDateTo}
                setValue={handleSelectDateTo}
                placeholder="Chọn thời gian"
                direction="vertical"
                className="flex-1"
                picker={"date"}
              />
            </div>
            <div className="flex pr-5 ">
              <Button
                type="primary"
                onClick={showExportModal}
                className={`${innerstyles.customButton} mr-4`}
              >
                <span className={`${styles.searchBtnText}`}>Xuất báo cáo vi phạm</span>
              </Button>

              <Modal style={{ marginTop: "-70px" }} width={1000} title="Xuất báo cáo" open={isExportModalOpen} onOk={loading ? null : handleExportOk} onCancel={handleExportCancel}>
                <Spin spinning={loading} tip="Đang xuất dữ liệu. Vui lòng đợi...">

                  <InfoFieldDatePicker
                    label={"Từ Ngày"}
                    value={selectedExportDateFrom}
                    setValue={handleSelectExportDateFrom}
                    placeholder="Chọn thời gian"
                    direction="vertical"
                    className="flex-1"
                    picker={"date"}
                  />
                  <InfoFieldDatePicker
                    label={"Tới ngày"}
                    value={selectedExportDateTo}
                    setValue={handleSelectExportDateTo}
                    placeholder="Chọn thời gian"
                    direction="vertical"
                    className="flex-1"
                    picker={"date"}
                  />
                </Spin>

              </Modal>
            </div>
          </div>
          {dateError ? (
            <span className={`mx-6 mt-4`} style={{ color: "red" }}>
              * Ngày bắt đầu không thể lớn hơn ngày kết thúc
            </span>
          ) : (
            ""
          )}
          <div className={`mx-6 mt-5`}>
            <Form form={form} component={false}>
              <CusTable
                rowKey="id"
                data={violateData}
                columns={columns}
                loading={tableLoading}
                havePagination={totalItems > pageSize.value}
                rowClassName="editable-row"
                page={currentPage}
                setPage={setCurrentPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                totalItems={totalItems}
                scroll={responsive.tableScroll()}
              // scroll={{ x: 700 }}
              />
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
