import { Modal, Spin } from "antd";
import { Notification } from "common/Notification";
import FilePicker from "common/filePicker/FilePicker";
import FileShower from "common/filePicker/component/FileShower";
import { useAttendanceConext } from "page/diemDanh/Context";
import React, { memo, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { studentService } from "services/studentService";
import { DANH_SACH_HOC_SINH_BAN_TRU_TEMPLATE } from "util/constants";
import styles from "../styles.module.css";

const ImportBoardingStudentModal = memo(({ visible, onClose }) => {
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;
  const { setIsRefresh } = useAttendanceConext();
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState(null);

  const disableBtn = useMemo(() => {
    if (files) {
      return false;
    }
    return true;
  }, [files]);

  const handleCancel = useCallback(() => {
    setFiles(null);
    onClose?.();
  }, [onClose]);

  const handleAddAttachments = (values) => {
    const temp = [...values];
    setFiles(temp);
  };

  const handleRemoveAttachment = (_value, index) => {
    let temp = [...files];
    temp.splice(index, 1);
    setFiles(temp);
  };

  const handleOk = useCallback(async () => {
    const formData = new FormData();
    formData.append("file", files?.[0]);
    setLoading(true);
    try {
      const response = await studentService.importBoardingStudent(
        accountId,
        formData
      );
      if (response.status === 204) {
        Notification.sendSuccess("Nhập liệu thành công.");
        setIsRefresh?.(true);
      }
    } catch (e) {
    } finally {
      setLoading(false);
      handleCancel?.();
    }
  }, [accountId, files, handleCancel, setIsRefresh]);

  return (
    <Modal
      title="Nhập liệu từ file Excel"
      open={visible}
      onOk={loading ? null : handleOk}
      destroyOnClose={true}
      onCancel={handleCancel}
      maskClosable={false}
      okButtonProps={{
        disabled: disableBtn || loading,
        className: disableBtn || loading ? styles.disabledBtn : "",
      }}
    >
      <a
        href={DANH_SACH_HOC_SINH_BAN_TRU_TEMPLATE}
        target="_blank"
        rel="noreferrer"
        download
        className="text-blueDefault cursor-pointer"
      >
        Tải file mẫu
      </a>
      <Spin spinning={loading} tip="Đang xử lý dữ liệu. Vui lòng đợi ...">
        <div className="mTop12 flex items-start">
          <div className="mRight12">
            <FilePicker setFiles={handleAddAttachments} />
          </div>
          <FileShower
            files={files}
            removeFile={handleRemoveAttachment}
            fileOnClick={() => {}}
          />
        </div>
      </Spin>
    </Modal>
  );
});

export default ImportBoardingStudentModal;
