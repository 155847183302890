import { Select } from "antd";
import {
  anualLeaveActive,
  chevronDownIcon,
  lateActive,
  noAuthLeaveActive,
  presentActive,
} from "asset/img/Images";
import { Notification } from "common/Notification";
import Img from "common/image/Img";
import moment from "moment";
import { useAttendanceConext } from "page/diemDanh/Context";
import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import {
  createAttendanceOneStudent,
  getAttendanceEvent,
  updateAttendanceOneStudent,
} from "util/APIs";
import { ATTENDANCE_STATUS } from "util/constant";
import styles from "../styles.module.css";

const { Option } = Select;

const SelectAttendance = memo(
  ({ studentId, date, attendanceId, attendanceStatus }) => {
    const userInfo = useSelector((state) => state?.app?.userInfo);
    const accountId = userInfo?.account?.id;
    const { sectionId, lessonId, setIsRefresh } = useAttendanceConext();
    const [loading, setLoading] = useState(false);

    const handleOnChange = async (status) => {
      setLoading(true);
      const aeParams = {
        "filters[sessionId]": sectionId,
        "filters[date][$eq]": moment(date).format("YYYY-MM-DD"),
      };
      const attendanceEvent = await getAttendanceEvent(accountId, aeParams);
      const ae = attendanceEvent.data?.data?.[0];

      if (ae?.attendance === false) {
        Notification.sendWarning(
          `Dữ liệu điểm danh ngày ${moment(date).format(
            "DD-MM-YYYY"
          )} chưa được tổng hợp`
        );
        setLoading(false);
      } else {
        const item = {
          date: moment(date).format("YYYY-MM-DD"),
          lessons: lessonId,
          sessions: sectionId,
          students: studentId,
          workflow_state: status,
          staff: [userInfo?.user?.id],
        };
        if (attendanceId) {
          const payload = {
            data: item,
          };
          try {
            const res = await updateAttendanceOneStudent(
              accountId,
              attendanceId,
              payload
            );
            if (res?.status === 200) {
              setIsRefresh(true);
              Notification.sendSuccess("Điểm danh thành công");
              return;
            }
          } catch (err) {
          } finally {
            setLoading(false);
          }
        } else {
          const payload = {
            data: {
              ...item,
            },
          };
          try {
            const res = await createAttendanceOneStudent(accountId, payload);
            if (res?.status === 200) {
              setIsRefresh(true);
              Notification.sendSuccess("Điểm danh thành công");
              return;
            }
          } catch (err) {
          } finally {
            setLoading(false);
          }
        }
      }
    };

    const renderOption = (item) => {
      let img = null;
      if (item.shortName === "U") img = <span></span>;
      if (item.shortName === "C")
        img = <img src={presentActive} alt="Có mặt" width={25} />;
      if (item.shortName === "T")
        img = <img src={lateActive} alt="Trễ" width={25} />;
      if (item.shortName === "P")
        img = <img src={anualLeaveActive} alt="Nghỉ có phép" width={25} />;
      if (item.shortName === "K")
        img = <img src={noAuthLeaveActive} alt="Nghỉ không phép" width={25} />;

      return (
        <Option value={item.key} key={item.key} className={styles.option}>
          {img}
        </Option>
      );
    };

    return (
      <Select
        value={attendanceStatus}
        onChange={handleOnChange}
        bordered={false}
        showArrow={false}
        suffixIcon={<Img src={chevronDownIcon} alt="Select" />}
        // placeholder={placeholder}
        disabled={loading}
        className={`w-full text-center attendance-select ${styles.select}`}
      >
        {ATTENDANCE_STATUS.map(renderOption)}
      </Select>
    );
  }
);

export default SelectAttendance;
