import {
  // banner,
  chevronRightSmall,
  demoAvatar,
  logoutIcon,
  setting,
  shieldQuestion,
  shieldTick,
  userBlue,
} from "asset/img/Images";
import Avatar from "common/avatar/Avatar";
import MobileHeader from "mobile/layout/header/MobileHeader";
import { HEADER_TYPE } from "mobile/util/mobileConstant";
import React from "react";
import { useHistory } from "react-router-dom";
import { logout } from "util/APICaller";
// import { DEFAULT_AVATAR } from "util/constant";
// import { mrHeight, mrWidth } from "util/helper";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getRoleType } from "util/common";
import { USER_ROLE } from "util/constants";
import { mrWidth } from "util/helper";
import { routes } from "util/routes";
import styles from "./styles.module.css";

export default function MenuPage() {
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const userRole = getRoleType(userInfo?.user?.roles);
  const history = useHistory();

  const MENU_STUDENT = [
    {
      icon: userBlue,
      name: "Đăng ký ăn trưa",
      key: "lunchRegistry",
      link: routes.LUNCH_REGISTRY,
      onClick: () => {
        history.push(routes.LUNCH_REGISTRY);
      },
    },
  ];
  const MENU_CANTEEN = [
    {
      icon: userBlue,
      name: "Thực đơn nhà ăn",
      key: "canteen",
      link: routes.CANTEEN_WEEK,
      onClick: () => {
        history.push(routes.CANTEEN_WEEK);
      },
    },
    {
      icon: userBlue,
      name: "Danh sách đăng ký",
      key: "canteenRegis",
      link: "/canteen-management/lunch-register",
      onClick: () => {
        history.push("/canteen-management/lunch-register");
      },
    },
  ];
  const ADMIN_CANTEEN = [
    {
      icon: userBlue,
      name: "Hồ sơ",
      key: "profile",
      link: routes.PROFILE,
      onClick: () => {
        history.push(routes.PROFILE);
      },
    },
    {
      icon: userBlue,
      name: "Quản lý nhân sự",
      key: "staff_management",
      link: routes.ADMIN_STAFF_MANAGEMENT,
      onClick: () => {
        history.push(routes.ADMIN_STAFF_MANAGEMENT);
      },
    },
    {
      icon: userBlue,
      name: "Thông tin học sinh ",
      key: "student_info",
      link: routes.STUDENT_INFO,
      onClick: () => {
        history.push(routes.STUDENT_INFO);
      },
    },
    {
      icon: userBlue,
      name: "Điểm danh",
      key: "attendance",
      link: routes.ATTENDANCE,
      onClick: () => {
        history.push(routes.ATTENDANCE);
      },
    },
    {
      icon: shieldQuestion,
      name: "Vi phạm",
      key: "evaluation",
      link: routes.EVALUATION,
      onClick: () => {
        history.push(routes.EVALUATION);
      },
    },
    {
      icon: shieldQuestion,
      name: "Bảng điểm",
      key: "gradebook",
      link: routes.GRADEBOOK,
      onClick: () => {
        history.push(routes.GRADEBOOK);
      },
    },
    {
      icon: shieldTick,
      name: "Đánh giá kỷ luật",
      key: "violate",
      link: routes.VIOLATE,
      onClick: () => {
        history.push(routes.VIOLATE);
      },
    },
    {
      icon: shieldTick,
      name: "Sổ đầu bài điện tử",
      key: "register_notebooks",
      link: routes.REGISTER_NOTEBOOKS,
      onClick: () => {
        history.push(routes.REGISTER_NOTEBOOKS);
      },
    },
    {
      icon: setting,
      name: "Quản lý thông báo",
      key: "message",
      link: routes.ADMIN_NOTIFICATION,
      onClick: () => {
        history.push(routes.ADMIN_NOTIFICATION);
      },
    },
    {
      icon: userBlue,
      name: "Thực đơn nhà ăn",
      key: "canteen",
      link: routes.ADMIN_CANTEEN,
      onClick: () => {
        history.push(routes.ADMIN_CANTEEN);
      },
    },
    {
      icon: userBlue,
      name: "Danh sách đăng ký",
      key: "canteenRegis",
      link: "/canteen-management/lunch-register",
      onClick: () => {
        history.push("/canteen-management/lunch-register");
      },
    },
  ];

  const PROFILE_MENU = [
    {
      icon: userBlue,
      name: "Hồ sơ",
      key: "profile",
      link: routes.PROFILE,
      isShow: true,
      onClick: () => {
        history.push(routes.PROFILE);
      },
    },
    {
      icon: userBlue,
      name: "Quản lý nhân sự",
      key: "staff_management",
      link: routes.ADMIN_STAFF_MANAGEMENT,
      isShow: true,
      onClick: () => {
        history.push(routes.ADMIN_STAFF_MANAGEMENT);
      },
    },
    {
      icon: userBlue,
      name: "Thông tin học sinh ",
      key: "student_info",
      link: routes.STUDENT_INFO,
      isShow: true,
      onClick: () => {
        history.push(routes.STUDENT_INFO);
      },
    },
    {
      icon: userBlue,
      name: "Điểm danh",
      key: "attendance",
      link: routes.ATTENDANCE,
      isShow: true,
      onClick: () => {
        history.push(routes.ATTENDANCE);
      },
    },
    {
      icon: shieldQuestion,
      name: "Vi phạm",
      key: "evaluation",
      link: routes.EVALUATION,
      isShow: true,
      onClick: () => {
        history.push(routes.EVALUATION);
      },
    },
    {
      icon: shieldQuestion,
      name: "Bảng điểm",
      key: "gradebook",
      link: routes.GRADEBOOK,
      isShow: true,
      onClick: () => {
        history.push(routes.GRADEBOOK);
      },
    },
    {
      icon: shieldTick,
      name: "Đánh giá kỷ luật",
      key: "violate",
      link: routes.VIOLATE,
      isShow: true,
      onClick: () => {
        history.push(routes.VIOLATE);
      },
    },
    {
      icon: shieldTick,
      name: "Sổ đầu bài điện tử",
      key: "register_notebooks",
      link: routes.REGISTER_NOTEBOOKS,
      isShow: true,
      onClick: () => {
        history.push(routes.REGISTER_NOTEBOOKS);
      },
    },
    {
      icon: setting,
      name: "Quản lý thông báo",
      key: "message",
      link: routes.ADMIN_NOTIFICATION,
      isShow: true,
      onClick: () => {
        history.push(routes.ADMIN_NOTIFICATION);
      },
    },
    {
      icon: setting,
      name: "Thay đổi mật khẩu",
      key: "change-password",
      link: routes.CHANGE_PASSWORD,
      isShow: userInfo?.isLocalLogin,
      onClick: () => {
        history.push(routes.CHANGE_PASSWORD);
      },
    },
    // {
    //   icon: shieldTick,
    //   name: "Account",
    //   key: "account",
    //   onClick: () => {},
    // },
    // {
    //   icon: setting,
    //   name: "Setting",
    //   key: "setting",
    //   onClick: () => {},
    // },
    // {
    //   icon: shieldQuestion,
    //   name: "About",
    //   key: "about",
    //   onClick: () => {},
    // },
  ];

  const renderMenuItem = (item, index) => {
    return (
      <Link
        className={`flex justify-between items-center ${
          index !== 0 && "mTop24"
        }`}
        // onClick={item?.onClick}
        to={item.link}
        key={item.key}
      >
        <div className={`flex items-center`}>
          <Avatar src={item?.icon} size={40} className={styles.itemIcon} />
          <span className={`mLeft14 ${styles.itemName}`}>{item?.name}</span>
        </div>
        <div>
          <img src={chevronRightSmall} alt="Go in" />
        </div>
      </Link>
    );
  };

  const renderMenuList = () => {
    switch (userRole) {
      case USER_ROLE.HOC_SINH:
        return MENU_STUDENT?.map(renderMenuItem);
      case USER_ROLE.NHA_AN:
        return MENU_CANTEEN?.map(renderMenuItem);
      case USER_ROLE.GIAM_THI_TONG:
        return ADMIN_CANTEEN?.map(renderMenuItem);
      default:
        return PROFILE_MENU?.map(renderMenuItem);
    }
  };

  return (
    <div className={`flex flex-col ${styles.root}`}>
      <MobileHeader type={HEADER_TYPE.brandname} />
      <div className="flex-1 flex flex-col">
        <div>
          <div className={`flex items-center ${styles.infoWrapper}`}>
            <div className={`flex`}>
              <Avatar
                src={userInfo?.image_url?.[0] || demoAvatar}
                size={mrWidth(58)}
                className={styles.avatar}
              />
              <div className="mLeft12 flex flex-col justify-center">
                <span className={styles.hiText}>Xin chào</span>
                <span className={styles.fullName}>
                  {userInfo?.user?.full_name || ""}
                </span>
              </div>
            </div>
          </div>
          <div className={styles.menuWrapper}>{renderMenuList()}</div>
          <div
            className={`pTop24 flex items-center ${styles.signoutWrapper}`}
            onClick={() => logout(history)}
          >
            <Avatar
              src={logoutIcon}
              size={40}
              className={styles.logoutBtn}
              imgIsRound={false}
            />
            <span className={`mLeft14 ${styles.signout}`}>Đăng xuất</span>
          </div>
        </div>

        {/* <div
          style={{
            backgroundImage: `url(${banner})`,
            width: mrWidth(327),
            height: mrHeight(85),
            minHeight: "85px",
            marginTop: mrHeight(20),
          }}
          className={styles.banner}
          onClick={() => {}}
        >
          <span className={styles.howToHelp}>How can we help you?</span>
        </div> */}
      </div>
    </div>
  );
}
