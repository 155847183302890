import RectangleTab from "common/RectangleTabs/RectangleTab";
import CustomBreadcrumb from "common/breadcrumb/CustomBreadcrumb";
import IsMobile from "hook/isMobile";
import MobileHeader from "mobile/layout/header/MobileHeader";
import { HEADER_TYPE } from "mobile/util/mobileConstant";
import HeaderAttendance from "page/diemDanh/header";
import BoardingAttendance from "page/diemDanh/tabs/boardingAttendance";
import DateAttendance from "page/diemDanh/tabs/dateAttendance";
import LunchAttendance from "page/diemDanh/tabs/lunchAttendance";
import MonthAttendance from "page/diemDanh/tabs/monthAttendance";
import WeekAttendance from "page/diemDanh/tabs/weekAttendance";
import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { isGiamThiTongRole, isManageRole } from "util/common";
import { STUDENT_MANAGEMENT, routes } from "util/routes";
import styles from "./styles.module.css";

const Inner = memo(() => {
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const isUsingMobile = IsMobile();
  const location = useLocation();

  const tabList = useMemo(() => {
    const lst = [
      {
        title: "Theo ngày",
        link: routes.ATTENDANCE,
        component: DateAttendance,
      },
      {
        title: "Theo tuần",
        link: routes.ATTENDANCE_BY_WEEK,
        component: WeekAttendance,
      },
      {
        title: "Theo tháng",
        link: routes.ATTENDANCE_BY_MONTH,
        component: MonthAttendance,
      },
      {
        title: "Ăn trưa",
        link: routes.ATTENDANCE_BY_LUNCH,
        component: LunchAttendance,
      },
      {
        title: "Bán trú",
        link: routes.ATTENDANCE_BY_BOARDING,
        component: BoardingAttendance,
      },
    ];
    return lst;
  }, []);

  const renderComp = useMemo(() => {
    switch (`${location.pathname}${location.search}`) {
      case routes.ATTENDANCE:
        return <DateAttendance />;
      case routes.ATTENDANCE_BY_WEEK:
        return <WeekAttendance />;
      case routes.ATTENDANCE_BY_MONTH:
        return <MonthAttendance />;
      case routes.ATTENDANCE_BY_LUNCH:
        return <LunchAttendance />;
      case routes.ATTENDANCE_BY_BOARDING:
        return <BoardingAttendance />;
      default:
        return;
    }
  }, [location.pathname, location.search]);

  const headerClassName = useMemo(() => {
    let clsName = styles.wrapperHeader;
    if (isGiamThiTongRole(userInfo?.user?.roles)) {
      clsName = styles.wrapperGiamThiTongHeader;
    } else if (isManageRole(userInfo?.user?.roles)) {
      clsName = styles.wrapperManageHeader;
    }
    return clsName;
  }, [userInfo?.user?.roles]);

  return (
    <div className={`flex flex-col h-full`}>
      {isUsingMobile ? (
        <MobileHeader
          type={HEADER_TYPE.middleTitle}
          title="Điểm danh"
          haveNotiButton={false}
        />
      ) : (
        <CustomBreadcrumb
          crumbs={[STUDENT_MANAGEMENT, routes.ATTENDANCE]}
          style={styles.breadcrumb}
        />
      )}
      <div className={`py-4 section-shadow bg-white`}>
        <div className={`px-8 mb-6 ${headerClassName}`}>
          <span className={`${styles.titleDiemDanh}`}>Điểm danh</span>
          <HeaderAttendance />
        </div>
        <div className={`flex px-8 overflow-x-scroll`}>
          {tabList.map((item) => (
            <Link
              className={`${
                item.link === `${location.pathname}${location.search}`
                  ? "active"
                  : ""
              }`}
              to={item.link}
            >
              <RectangleTab
                isSelected={`${
                  item.link === `${location.pathname}${location.search}`
                    ? "active"
                    : ""
                }`}
                text={item.title}
              />
            </Link>
          ))}
        </div>
        <div className="pt-5">{renderComp}</div>
      </div>
    </div>
  );
});

export default Inner;
