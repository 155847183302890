import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  facebookIcon,
  globeIcon,
  insIcon,
  thuThiemLogo,
} from "asset/img/Images";
import InfoFieldPassword from "common/CusInfoField/infoFieldPassword/InfoFieldPassword";
import InfoFieldText from "common/CusInfoField/infoFieldText/InfoFieldText";
import Img from "common/image/Img";
import useHandleLogin from "hook/handleLoginSuccess";
import IsMobile from "hook/isMobile";
import React, { useState } from "react";
import { login } from "util/APIs";
import { is1920OrMore } from "util/constant";
import { IS_LOCAL_LOGIN } from "util/constants";
import { rHeight, rWidth } from "util/helper";
import Slide from "./component/slide/Slide";
import styles from "./styles.module.css";

export default function LocalLoginPage() {
  const [handleLoginSuccess] = useHandleLogin();
  const isUsingMobile = IsMobile();
  const smallImages = [1, 2, 3, 4];

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");

  const renderSmallImage = (_item, index) => (
    <div
      style={{
        width: "48px",
        height: "48px",
        borderRadius: "50%",
        marginLeft: index !== 0 ? "24px" : 0,
        backgroundColor: "#fafafa",
      }}
      key={index}
    >
      {/* <Img src={item} alt='People' /> */}
    </div>
  );

  const handleLogin = async () => {
    setLoading(true);
    try {
      const res = await login(name, password);
      if (res?.status === 200) {
        localStorage.setItem(IS_LOCAL_LOGIN, "yes");
        handleLoginSuccess(res?.data);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex">
      {isUsingMobile ? (
        ""
      ) : (
        <div
          className={` flex flex-col justify-end items-start bg-cover bg-no-repeat ${styles.leftWrapper}`}
          style={{
            width: 470,
            padding: `0 60px ${is1920OrMore ? "50" : "12"}px`,
          }}
        >
          <span className={styles.brandName}>Educentric</span>
          <Slide />
          <div className="flex mTop32">{smallImages.map(renderSmallImage)}</div>
          <div style={{ marginTop: rHeight(108) }} className={`flex`}>
            <div style={{ width: "32px", height: "32px" }}>
              <Img src={facebookIcon} alt="facebook" />
            </div>
            <div
              style={{
                width: "32px",
                height: "32px",
                marginLeft: "8px",
              }}
            >
              <Img src={globeIcon} alt="website" />
            </div>
            <div
              style={{
                width: "32px",
                height: "32px",
                marginLeft: "8px",
              }}
            >
              <Img src={insIcon} alt="instagram" />
            </div>
          </div>
        </div>
      )}

      <div
        style={{ paddingTop: rHeight(121) }}
        className={`h-screen bg-cover bg-no-repeat flex flex-col justify-start items-center flex-1 ${styles.loginWrapper}`}
      >
        <div style={{ width: rWidth(88), height: rWidth(88) }}>
          <Img src={thuThiemLogo} alt="logo" />
        </div>
        <span className={styles.greetText}>Chào mừng đến với Educentric</span>
        <span className={styles.title}>ĐĂNG NHẬP</span>
        <div
          className={`text-white flex flex-col ${styles.formWrapper}`}
          style={{ width: "90%" }}
        >
          <InfoFieldText
            label="Tên đăng nhập"
            className={styles.mrInput}
            isRequired={true}
            value={name}
            setValue={setName}
            placeholder="Tên đăng nhập"
            direction="vertical"
          />
          ,
          <InfoFieldPassword
            label="Mật khẩu"
            className={styles.mrInput}
            isRequired={true}
            value={password}
            setValue={setPassword}
            placeholder="Mật khẩu"
            direction="vertical"
          />
          ,
          <button
            style={{ height: rHeight(59), marginTop: rHeight(24) }}
            className={`flex justify-center items-center bg-blueDefault ${styles.loginButton}`}
            onClick={handleLogin}
            disabled={loading}
          >
            {loading && <FontAwesomeIcon icon={faCircleNotch} spin />}
            {!loading && <span className={styles.continue}>Đăng nhập</span>}
          </button>
        </div>
      </div>
    </div>
  );
}
