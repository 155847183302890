import InfoFieldDatePicker from "common/CusInfoField/infoFieldDatePicker/InfoFieldDatePicker";
import CustomBreadcrumb from "common/breadcrumb/CustomBreadcrumb";
import IsMobile from "hook/isMobile";
import MobileHeader from "mobile/layout/header/MobileHeader";
import { HEADER_TYPE } from "mobile/util/mobileConstant";
import moment from "moment";
import DisheListItem from "page/studentLunchRegisterPage/components/DisheListItem";
import MenuListRegistered from "page/studentLunchRegisterPage/components/MenuListRegistered";
import React, { memo, useMemo, useState } from "react";
import { routes } from "util/routes";
import styles from "./styles.module.css";

const StudentLunchRegisterPage = memo(() => {
    const isUsingMobile = IsMobile();

    const [selectedDate, setSelectedDate] = useState(moment().add(1, "weeks"));
    const [selectedWeek, setSelectedWeek] = useState(moment().format("wo"));
    const [startDateWeek, setStartDateWeek] = useState(
        moment().add(1, "weeks").clone().startOf("week").format("YYYY-MM-DD")
    );
    const [endDateWeek, setEndDateWeek] = useState(
        moment().add(1, "weeks").clone().endOf("week").format("YYYY-MM-DD")
    );

    const handleSetSelectedDate = (value) => {
        const selectedDate = moment(value);
        const startOfWeek = selectedDate
            .clone()
            .startOf("week")
            .format("YYYY-MM-DD");
        const endOfWeek = selectedDate
            .clone()
            .endOf("week")
            .format("YYYY-MM-DD");

        setSelectedDate(selectedDate);
        setSelectedWeek(selectedDate.format("wo"));
        setStartDateWeek(startOfWeek);
        setEndDateWeek(endOfWeek);
    };

    // học sinh đăng ký món ăn cho tuần tới - được xem ds mon ăn đã đăng ký từ tuần hiện tại trở về trước
    // check current date with week selected (kiểm tra ngày hiện tại với tuần được chọn để biết tuần chọn không phải chọn tuần tới)
    const isFutureWeek = useMemo(() => {
        const dateOne = moment(endDateWeek);
        const dateTwo = moment(new Date().toISOString().slice(0, 10));
        const result = dateOne.diff(dateTwo, "days");

        if (result > 7) {
            return true;
        } else {
            return false;
        }
    }, [endDateWeek]);

    const disabledDate = (current) => {
        // Can not select days after next week
        return current && current > moment().add(1, "weeks").endOf("week");
      };

    return (
        <div className={`flex flex-col h-full`}>
            {isUsingMobile ? (
                <MobileHeader
                    type={HEADER_TYPE.middleTitle}
                    title="Đăng ký ăn trưa"
                    haveNotiButton={false}
                />
            ) : (
                <CustomBreadcrumb crumbs={[routes.LUNCH_REGISTRY]} />
            )}
            <div className={`py-4 section-shadow bg-white`}>
                <div className={`${styles.wrapper}`}>
                    {isUsingMobile ? (
                        ""
                    ) : (
                        <h2 className={`${styles.header}`}>Đăng ký ăn trưa </h2>
                    )}
                </div>

                <div className={`${styles.wrapperFilters}`}>
                    <InfoFieldDatePicker
                        label={"Tuần"}
                        value={selectedDate}
                        setValue={handleSetSelectedDate}
                        placeholder="Chọn thời gian"
                        direction="vertical"
                        className="flex-1"
                        format={"wo-YYYY"}
                        picker={"week"}
                        disabledDate={disabledDate}
                    />
                </div>

                <div className={`mx-6 my-5`}>
                    {isFutureWeek ? (
                        <DisheListItem
                            startDateWeek={startDateWeek}
                            endDateWeek={endDateWeek}
                        />
                    ) : (
                        <MenuListRegistered
                            startDateWeek={startDateWeek}
                            endDateWeek={endDateWeek}
                        />
                    )}
                </div>
            </div>
        </div>
    );
});

export default StudentLunchRegisterPage;
