import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  facebookIcon,
  globeIcon,
  insIcon,
  thuThiemLogo,
} from "asset/img/Images";
import Img from "common/image/Img";
import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TEACHER_DEFAULT_LINK, is1920OrMore } from "util/constant";
import { rHeight, rWidth } from "util/helper";
import Slide from "./component/slide/Slide";
import styles from "./styles.module.css";
import { redirectAuth2 } from "util/APIs";
import { SIDEBAR_ITEMS } from "util/sidebars";
import { setSidebarActiveKey } from "redux/action/app";
import { routes } from "util/routes";

export default function LoginPage() {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const smallImages = [1, 2, 3, 4];
  const sidebarActiveKey = useSelector((state) => state.app.sidebarActiveKey);
  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      let sidebar_item = findSidebarLink(sidebarActiveKey);
      if (!sidebar_item) {
        sidebar_item = SIDEBAR_ITEMS[0];
        dispatch(setSidebarActiveKey(sidebar_item.key));
      }
      history.push(sidebar_item.link);
    }
  }, []);
  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  const handleContinue = async () => {
    setLoading(true);
    window.location.replace(redirectAuth2);
    setLoading(false);
  };

  const findSidebarLink = (key) => SIDEBAR_ITEMS.find((i) => i.key === key);
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////
  const renderSmallImage = (_item, index) => (
    <div
      style={{
        width: "48px",
        height: "48px",
        borderRadius: "50%",
        marginLeft: index !== 0 ? "24px" : 0,
        backgroundColor: "#fafafa",
      }}
      key={index}
    >
      {/* <Img src={item} alt='People' /> */}
    </div>
  );
  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <div className="flex">
      <div
        className={` flex flex-col justify-end items-start bg-cover bg-no-repeat ${styles.leftWrapper}`}
        style={{
          width: 470,
          padding: `0 60px ${is1920OrMore ? "50" : "12"}px`,
        }}
      >
        <span className={styles.brandName}>Educentric</span>
        <Slide />
        <div className="flex mTop32">{smallImages.map(renderSmallImage)}</div>
        <div style={{ marginTop: rHeight(108) }} className={`flex`}>
          <div style={{ width: "32px", height: "32px" }}>
            <Img src={facebookIcon} alt="facebook" />
          </div>
          <div
            style={{
              width: "32px",
              height: "32px",
              marginLeft: "8px",
            }}
          >
            <Img src={globeIcon} alt="website" />
          </div>
          <div
            style={{
              width: "32px",
              height: "32px",
              marginLeft: "8px",
            }}
          >
            <Img src={insIcon} alt="instagram" />
          </div>
        </div>
      </div>
      <div
        style={{ paddingTop: rHeight(121) }}
        className={`h-screen bg-cover bg-no-repeat flex flex-col justify-start items-center flex-1 ${styles.loginWrapper}`}
      >
        <div style={{ width: rWidth(88), height: rWidth(88) }}>
          <Img src={thuThiemLogo} alt="logo" />
        </div>
        <span className={styles.greetText}>Chào mừng đến với Educentric</span>
        <span className={styles.title}>ĐĂNG NHẬP</span>
        <div
          className={`text-white flex flex-col ${styles.formWrapper}`}
          style={{ width: rWidth(452) }}
        >
          <button
            style={{ height: rHeight(59), marginTop: rHeight(24) }}
            className={`flex justify-center items-center bg-blueDefault ${styles.loginButton}`}
            onClick={handleContinue}
            disabled={loading}
          >
            {loading && <FontAwesomeIcon icon={faCircleNotch} spin />}
            {!loading && (
              <span className={styles.continue}>Đăng nhập Google</span>
            )}
          </button>
          <Link
            to={routes.LOCAL_LOGIN}
            style={{ height: rHeight(59), marginTop: rHeight(24) }}
            className={`flex justify-center items-center bg-blueDefault ${styles.loginButton}`}
          >
            <button disabled={loading}>
              {loading && <FontAwesomeIcon icon={faCircleNotch} spin />}
              {!loading && (
                <span className={styles.continue}>Đăng nhập với tài khoản</span>
              )}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
