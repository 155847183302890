import { get } from "util/APICaller";
import { API_URL } from "util/constant";

const exportAttendanceAbsent = (accountId, params) => {
  return get(
    `${API_URL}/accounts/${accountId}/attendances/export_absent`,
    params
  );
};

export const attendanceService = {
  exportAttendanceAbsent,
};
