import { cameraWhiteOutlined, demoAvatar } from "asset/img/Images";
import Avatar from "common/avatar/Avatar";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { updateUser } from "util/APIs";
import { SIDEBAR_EXPAND_WIDTH } from "util/constant";
import SidebarBody from "./component/SidebarBody";
import styles from "./styles.module.css";

export default function Sidebar() {
  const collapse = useSelector((state) => state.app.sidebarCollapse);
  const userInfo = useSelector((state) => state?.app?.userInfo);

  const [imageUrls, setImageUrls] = useState(null);

  useEffect(() => {
    if (userInfo) {
      if (userInfo?.user?.files != null) {
        userInfo.user.files = JSON.parse(userInfo?.user?.files);
        setImageUrls(userInfo?.user?.files[0]?.url);
      }
    }
    // if (localStorage.getItem("user_info")) {
    //   getTeacherData().then((result) => {
    //     if (result.data?.user?.files != null) {
    //       result.data.user.files = JSON.parse(result.data?.user?.files)
    //       setImageUrls(result?.data?.user?.files[0]?.url)
    //     }
    //   })
    // }
  }, [userInfo]);

  const handlechangefile = async (e) => {
    const file = e.target.files;
    const formData = new FormData();

    formData.append("file", file[0]);
    if (file) {
      const res = await updateUser(
        userInfo?.account?.id,
        userInfo?.user?.id,
        formData
      );
      if (res.status === 200) {
        setImageUrls(JSON.parse(res.data.files)[0].url);
      }
    }
  };
  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  return (
    <div
      style={{
        width: !collapse ? SIDEBAR_EXPAND_WIDTH : "unset",
        minWidth: !collapse ? SIDEBAR_EXPAND_WIDTH : "unset",
      }}
      className={styles.root}
    >
      <div
        className={`pHorizontal16 flex flex-col items-center ${styles.topInfoWrapper}`}
      >
        <div className={`mTop16 ${styles.logo}`}>
          <Avatar
            src={imageUrls || demoAvatar}
            size={100}
            // className={styles?.avatar}
            imgClassName="w-full h-full"
          />
          <Avatar
            src={cameraWhiteOutlined}
            size={34}
            className={styles.changeAvatarBtn}
            imgClassName="w-full h-full"
            imgIsRound={false}
            onClick={handleClick}
          />
          <input
            type="file"
            onChange={handlechangefile}
            ref={hiddenFileInput}
            style={{ display: "none" }} // Make the file input element invisible
          />
        </div>
      </div>
      <div className={`mTop16 ${styles.scrolling}`}>
        <SidebarBody />
      </div>
    </div>
  );
}
