import { get, post, put } from "util/APICaller";
import { API_URL } from "util/constant";

const getMenuListByWeek = (accountId, params) => {
    return get(
        `${API_URL}/accounts/${accountId}/student/lunch-registry`,
        params
    );
};

const studentRegisterLunchByWeek = (accountId, body) => {
    return post(
        `${API_URL}/accounts/${accountId}/student/lunch-registry`,
        body
    );
};

const studentUpdateLunchByWeek = (accountId, id, body) => {
    return put(
        `${API_URL}/accounts/${accountId}/student/lunch-registry/${id}`,
        body
    );
};

export const lunchRegistryService = {
    getMenuListByWeek,
    studentRegisterLunchByWeek,
    studentUpdateLunchByWeek,
};
