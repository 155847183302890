import { Button, DatePicker, Form, Input, Modal, Select, Space } from "antd";
import { newEditIcon, uploadIcon } from "asset/img/Images";
import InfoFieldDatePicker from "common/CusInfoField/infoFieldDatePicker/InfoFieldDatePicker";
import InfoFieldSelect from "common/CusInfoField/infoFieldSelect/InfoFieldSelect";
import CusTable from "common/CusTable";
import CustomBreadcrumb from "common/breadcrumb/CustomBreadcrumb";
import IsMobile from "hook/isMobile";
import _ from "lodash";
import MobileHeader from "mobile/layout/header/MobileHeader";
import { HEADER_TYPE } from "mobile/util/mobileConstant";
import moment from "moment";
import styles from "page/attendancePage/component/styles.module.css";
import innerstyles from "./styles.module.css";

import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getAttendanceEvaluationList,
  getClassListByGrade,
  getGradeList,
  getRuleList,
  getSessionList,
  getStudentViolateRules,
  getStudentViolateRulesList,
  getAttendanceByDay,
  createStudentViolateRules,
  updateStudentViolateRules,
  getStudentListByClass,
  exportViolate,
} from "util/APIs";
import { DATE_FORMAT, TABLE_PAGE_SIZES, is1920OrMore } from "util/constant";
import { STUDENT_MANAGEMENT, routes } from "util/routes";
import EvaluationField from "./component/EvaluationField";
import violateStyles from "page/violateManagementPage/styles.module.css";
import evaluationStyles from "./styles.module.css";
import FileList, { FileItem } from "common/FilePreviewer/FileList";
import IconFile from "asset/icons/IconFile";
import FilePicker from "common/filePicker/FilePicker";
import FileShower from "common/filePicker/component/FileShower";
import { Notification } from "common/Notification";
import { rule } from "postcss";

const { Option } = Select;

const FORMAT_DATE = "YYYY-MM-DD";

export default function EvaluationPage() {
  const isUsingMobile = IsMobile();
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;

  const isAllowToUpdate = true;

  const responsive = {
    setResponsiveCol: () => {
      if (!is1920OrMore) {
        columns[1].width = 120;
        columns[2].width = 120;
        columns[3].width = 120;
      }
    },
    nodeWrapperWidth: () => {
      if (is1920OrMore) return "560px";
      if (!is1920OrMore) return "560px";
    },
    tableScroll: () => {
      if (is1920OrMore) return {};
      else
        return {
          x: 1000,
          y: 490,
        };
    },
  };
  const [form] = Form.useForm();

  const schoolYear = useSelector(
    // (state) => state.schoolYear.selectedSchoolYear
    (state) => state
  );

  const [grade, setGrade] = useState(null);
  const [section, setSection] = useState(null);
  const [classId, setClassId] = useState(null);
  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZES[0]);
  const [gradeList, setGradeList] = useState([]);
  const [classList, setClassList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [editingKey, setEditingKey] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isRefresh, setIsRefresh] = useState(false);
  const [sectionList, setSectionList] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);
  const [violateData, setViolateData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment().format());
  const [ruleList, setRuleList] = useState([]);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [selectedWeekExport, setSelectedWeekExport] = useState(null);
  const [selectedDateFromExport, setSelectedDateFromExport] = useState(null);
  const [selectedDateToExport, setSelectedDateToExport] = useState(null);

  const [loading, setLoading] = useState(false);
  const [loadData, setLoadData] = useState(false);

  const viewFile = (values) => {
    const lst = values ? JSON.parse(values) : [];
    if (lst?.length) {
      const formattedFiles = lst?.map?.((item) => ({
        name: item.title,
        url: item.url,
        type: item.mimetype,
      }));
      return (
        <FileList attachments={formattedFiles}>
          <FileItem {...formattedFiles[0]}>
            {({ onOpen }) => {
              return (
                <div onClick={onOpen}>
                  <div className={`${evaluationStyles.viewFile}`}>
                    <IconFile />
                  </div>
                </div>
              );
            }}
          </FileItem>
        </FileList>
      );
    }
  };
  const columns = [
    {
      title: "STT",
      align: "center",
      key: "STT",
      render: (value, record, index) =>
        (currentPage - 1) * pageSize.value + index + 1,
      width: "10%",
      fixed: "left",
    },
    // {
    //   title: "Học sinh",
    //   align: "center",
    //   key: "Students",
    //   dataIndex: "students",
    //   render: (text) => <span>{text}</span>,
    //   width: "10%",
    // },
    {
      title: "Học sinh",
      align: "left",
      key: "students",
      dataIndex: "full_name",
      render: (text) => <span>{text}</span>,
      width: "25%",
    },
    // // {
    // //   title: "Mã học sinh",
    // //   dataIndex: ["students", 0, "moeat_id"],
    // //   editable: false,
    // //   align: "center",
    // //   render: (text) => <span>{text}</span>,
    // //   width: "15%",
    // // },

    {
      title: "Vi phạm",
      key: "rules",
      align: "left",
      width: "35%",
      render: (value, record) => {
        const editable = isEditing(record);
        const values = record.violate_student.length
          ? record.violate_student?.[0]?.rules?.map((item) => item.id)
          : "";
        return (
          <EvaluationField
            values={values}
            options={ruleList}
            disabled={!editable || !isAllowToUpdate}
            record={record}
            selectedDate={selectedDate}
            accountId={accountId}
            setIsRefresh={setIsRefresh}
          />
        );
      },
    },
    {
      title: "Files",
      align: "center",
      dataIndex: "files",
      render: (text, record) => viewFile(text),

      width: "5%",
    },
    {
      title: "",
      dataIndex: "id",
      align: "center",
      render: (_, record) => (
        <Space size={2}>
          <Button
            type="link"
            disabled={!isAllowToUpdate}
            onClick={() => edit(record)}
          >
            <img
              src={newEditIcon}
              alt="Edit"
              className="editIcon" // Ensure the CSS class is correctly defined
            />
          </Button>
        </Space>
      ),
      width: "10%",
    },
  ];
  // responsive.setResponsiveCol();

  const getGrade = useCallback(async () => {
    const res = await getGradeList(accountId);
    if (res?.status === 200) {
      const data = res?.data?.data;
      data.pop();
      setGradeList(data);

      if (data?.length > 0) {
        setGrade(data[0]?.id);
      }
    }
  }, [accountId]);

  const getClass = useCallback(
    async (gradeId) => {
      const res = await getClassListByGrade(accountId, gradeId);
      if (res?.status === 200) {
        const data = res?.data?.data;
        setClassList(data);

        if (data?.length > 0) {
          setClassId(data[0]?.id);
        }
      }
    },
    [accountId]
  );

  const getRules = useCallback(async () => {
    const res = await getRuleList(accountId);
    if (res?.status === 200) {
      const data = res?.data?.data;
      const options = data?.map((item) => ({
        category: item?.Category,
        label: item?.title,
        value: item?.id,
      }));

      const formatOptions = _.chain(options)
        // Group the elements of Array based on `cateogry` property
        .groupBy("category")
        // `key` is group's name (cateogry), `value` is the array of objects
        .map((value, key) => ({ label: key, options: value }))
        .value();

      setRuleList(formatOptions);
    }
  }, [accountId]);
  const getSectionList = useCallback(async () => {
    // const res = await getSetting(SETTING_GROUP_KEYS.section, setSectionList);
    // if (res?.data?.data?.[0]) {
    //   setSection(res?.data?.data?.[0]?.key);
    // }
    const params = {
      // orderBy: "title",
    };
    const res = await getSessionList(accountId, params);
    if (res?.status === 200) {
      const data = res?.data?.data;
      setSectionList(res?.data?.data);
      if (data?.length > 0) {
        // setSection(data[0]?.session_code);
        setSection(data[0]?.id);
      }
    }
  }, [accountId]);

  const getData = useCallback(
    async (page = null) => {
      try {
        if (page === null) page = currentPage;
        setTableLoading(true);
        if (classId) {
          const params = {
            "pagination[pageSize]": pageSize.value,
            "pagination[page]": page,
            "filters[classes.id]": classId,
            "filters[date]": moment(selectedDate).format(FORMAT_DATE),
            "filters[sessions.id]": section,
          };
          const res = await getStudentViolateRulesList(accountId, params);
          if (res?.status === 200) {
            const { data, pagination } = res?.data;
            setViolateData(res?.data?.data);
            // setTotalItems(res?.data?.pagination?.total);
            // setCurrentPage(res?.data?.pagination?.page);
          }
        }
      } catch (error) {
      } finally {
        setTableLoading(false);
      }
    },
    [currentPage, classId, pageSize.value, selectedDate, section, accountId]
  );

  const isEditing = (record) => record?.id === editingKey;

  const edit = (record) => {
    if (editingKey === record?.id) {
      setEditingKey("");
    } else {
      setEditingKey(record?.id);
    }
  };

  const handleSetSelectedDate = (value) => {
    const temp = moment(value).format();
    setSelectedDate(temp);
  };
  const handleSetSelectedWeekExport = (value) => {
    const temp = moment(value).format();
    const currentDate = new Date(temp);
    const dayOfWeek = currentDate.getDay();
    const startDate = new Date(currentDate);
    const endDate = new Date(currentDate);

    startDate.setDate(currentDate.getDate() - dayOfWeek); // Set start date to the beginning of the week
    endDate.setDate(currentDate.getDate() + (6 - dayOfWeek)); // Set end date to the end of the week

    setSelectedDateFromExport(moment(startDate).format("YYYY-MM-DD"));
    setSelectedDateToExport(moment(endDate).format("YYYY-MM-DD"));
    setSelectedWeekExport(temp);
  };

  useEffect(() => {
    if (schoolYear && schoolYear !== null) getGrade();
  }, [schoolYear, getGrade]);

  useEffect(() => {
    if (grade && grade !== null) {
      getClass(grade);
    }
    getSectionList();
  }, [grade, getClass, getSectionList]);

  useEffect(() => {
    getData();
    getRules();
  }, [classId, selectedDate, section, getData, getRules]);

  useEffect(() => {
    if (isRefresh) {
      getData();
      setIsRefresh(false);
    }
  }, [getData, isRefresh]);

  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [studentId, setStudentId] = useState(null);
  const [ruleId, setRuleId] = useState(null);
  const [violateDate, setViolateDate] = useState(null);
  const [sectionId, setSectionId] = useState(null);

  const [studentData, setStudentData] = useState([]);
  const [files, setFiles] = useState(null);
  const getDataClassList = useCallback(async () => {
    const params = {
      // "filters[date][$eq]": moment().format("YYYY-MM-DD"),
      // "filters[sessionId]": 1,
      // "filters[lessonId]": 1,
      orderBy: "stt",
    };
    if (classId) {
      const res = await getStudentListByClass(accountId, classId, params);
      if (res?.status === 200) {
        setStudentData(res?.data.data);
      }
    }
  }, [accountId, classId]);
  useEffect(() => {
    getDataClassList();
  }, [getDataClassList]);

  const showAddModal = () => {
    setShowModal(true);
  };
  const handleCancel = () => {
    setShowModal(false);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        if (values) {
          // setParentName(values.parentName);
          const date = form.getFieldValue("date");
          setViolateDate(moment(date).format("YYYY-MM-DD"));
          setRuleId(values.rules);
          setStudentId(values.studentName);
          setSectionId(values.section);
        }
        setShowConfirmModal(true);
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };

  const handleChangeStudent = (selectedStudentId) => {
    setStudentId(selectedStudentId);
  };
  const handleChangeRules = (selectedRuleId) => {
    setRuleId(selectedRuleId);
  };
  const handleChangeSection = (selectedSectionId) => {
    console.log("selectedSectionId", selectedSectionId);
    setSectionId(selectedSectionId);
  };
  const handleAddAttachments = (values) => {
    const temp = [...values];
    setFiles(temp);
  };
  const handleRemoveAttachment = (_value, index) => {
    let temp = [...files];
    temp.splice(index, 1);
    setFiles(temp);
  };

  const handleCloseModal = () => {
    setShowConfirmModal(false);
  };
  const handleCreateViolentStudent = async () => {
    try {
      setLoading(true);
      if (classId) {
        const getParams = {
          "filters[date][$eq]": violateDate,
          "filters[classes.id]": classId,
          "filters[students.id]": studentId,
          "filters[sessions.id]": section,
        };

        const getResult = await getStudentViolateRules(accountId, getParams);
        if (getResult.status === 200) {
          if (getResult?.data?.data.length === 0) {
            const payload = {
              date: violateDate,
              classes: [classId],
              students: [studentId],
              rules: ruleId,
              sessions: [section],
            };
            const formData = new FormData();
            formData.append("data", JSON.stringify(payload));
            if (files) {
              for (let f of files) {
                formData.append("file", f);
              }
            }
            const res = await createStudentViolateRules(accountId, formData);
            if (res?.status === 200) {
              Notification.sendSuccess("Gửi xin phép thành công.");
              setViolateDate(moment().format("YYYY-MM-DD"));
              setRuleId("");
              setStudentId("");
              setShowConfirmModal(false);
              setShowModal(false);
              setLoading(false);
              setLoadData((prev) => !prev);
            }
          } else {
            const violateStudentId = getResult?.data?.data[0]?.id;
            const rulesList = getResult?.data?.data[0]?.rules.map(
              (item) => item.id
            );
            const rules = [...rulesList, ...ruleId];
            const payload = {
              date: violateDate,
              rules: [...new Set(rules)],
            };
            const formData = new FormData();
            formData.append("data", JSON.stringify(payload));
            if (files) {
              for (let f of files) {
                formData.append("file", f);
              }
            }
            const res = await updateStudentViolateRules(
              accountId,
              violateStudentId,
              formData
            );
            if (res?.status === 200) {
              Notification.sendSuccess("Gửi xin phép thành công.");
              setViolateDate(moment().format("YYYY-MM-DD"));
              setRuleId("");
              setStudentId("");
              setShowConfirmModal(false);
              setShowModal(false);
              setLoading(false);
              setLoadData((prev) => !prev);
            }
          }
          getData();
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const showExportModal = () => {
    setIsExportModalOpen(true);
  };
  const handleExportOk = async () => {
    const params = {
      "filters[date][$gte]": selectedDateFromExport,
      "filters[date][$lte]": selectedDateToExport,
    };
    const res = await exportViolate(accountId, params);
    if (res.status === 200) {
      window.open(res.data.data, "_blank");
    }
    setIsExportModalOpen(false);
  };
  const handleExportCancel = () => {
    setIsExportModalOpen(false);
  };

  return (
    <div className={`flex flex-col h-full`}>
      {isUsingMobile ? (
        <MobileHeader
          type={HEADER_TYPE.middleTitle}
          title="Vi phạm"
          haveNotiButton={false}
        />
      ) : (
        <CustomBreadcrumb
          crumbs={[STUDENT_MANAGEMENT, routes.EVALUATION]}
          style={styles.breadcrumb}
        />
      )}
      <div className={`py-4 section-shadow bg-white`}>
        <div className={`pt-5`}>
          <div className={`${innerstyles.wrapperHeader}`}>
            <div className={`${violateStyles.wrapperFilters}`}>
              <InfoFieldSelect
                label="Khối"
                data={gradeList}
                value={grade}
                setValue={setGrade}
                direction="vertical"
                className="flex-1"
              />

              <InfoFieldSelect
                label="Lớp"
                data={classList}
                value={classId}
                setValue={setClassId}
                direction="vertical"
                className="flex-1"
              />

              <InfoFieldDatePicker
                label={"Ngày"}
                value={selectedDate}
                setValue={handleSetSelectedDate}
                placeholder="Chọn thời gian"
                direction="vertical"
                className="flex-1"
                picker={"date"}
              />

              <InfoFieldSelect
                label="Buổi"
                data={sectionList}
                value={section}
                setValue={setSection}
                direction="vertical"
                // onChange={handleFilterChange}
                // valueFieldName="session_code"
                valueTextName="title"
                allowClear={false}
                // className={`flex-1 mr-4 max-[912px]:w-1/2`}
              />
            </div>
            <div className="flex pr-5 ">
              <Button
                type="primary"
                onClick={showExportModal}
                className={`${innerstyles.customButton} mr-4`}
              >
                <span className={`${styles.searchBtnText}`}>
                  Xuất báo cáo tuần
                </span>
              </Button>
              <Modal
                style={{ marginTop: "-70px" }}
                width={1000}
                title="Xuất báo cáo"
                open={isExportModalOpen}
                onOk={handleExportOk}
                onCancel={handleExportCancel}
              >
                <InfoFieldDatePicker
                  label={"Chọn tuần"}
                  value={selectedWeekExport}
                  setValue={handleSetSelectedWeekExport}
                  placeholder="Chọn thời gian"
                  direction="vertical"
                  className="flex-1"
                  picker={"week"}
                />
              </Modal>
              <Button
                type="primary"
                onClick={showAddModal}
                className={`${innerstyles.customButton}`}
              >
                Thêm vi phạm
              </Button>
            </div>
          </div>

          <div className={`mx-6 mt-5`}>
            <Form form={form} component={false}>
              <CusTable
                rowKey="id"
                data={violateData}
                columns={columns}
                loading={tableLoading}
                havePagination={totalItems > pageSize.value}
                rowClassName="editable-row"
                page={currentPage}
                setPage={setCurrentPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                totalItems={totalItems}
                scroll={responsive.tableScroll()}
                // scroll={{ x: 700 }}
              />
            </Form>
          </div>
          <Modal
            style={{ marginTop: "-70px" }}
            width={800}
            title="Thêm lỗi vi phạm"
            open={showModal}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <Form
              className={`${innerstyles.form}`}
              form={form}
              name="violentForm"
            >
              <Form.Item
                name="studentName"
                label="Tên học sinh"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="Chọn học sinh vi phạm"
                  onChange={handleChangeStudent}
                  allowClear
                  className={`${styles.customSelect}`}
                >
                  {studentData &&
                    studentData.map((student) => (
                      <Select.Option key={student.id} value={student.id}>
                        {student.full_name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item label="Ngày vi phạm" name="date">
                <DatePicker className={`${styles.dateCustom}`} />
              </Form.Item>
              <Form.Item
                label="Lỗi vi phạm"
                name={["rules"]}
                className={styles.fieldItem}
              >
                <Select
                  placeholder="Chọn lỗi vi phạm"
                  options={ruleList}
                  mode="multiple"
                  className={`${styles.inputWrapper}`}
                >
                  {[].map((item) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <div className="d-flex">
                <p>Buổi: </p>
                <InfoFieldSelect
                  data={sectionList}
                  value={section}
                  setValue={setSection}
                  direction="vertical"
                  valueTextName="title"
                  allowClear={false}
                />
              </div>

              <div className="flex items-start my-4">
                <div className="mRight12">
                  <FilePicker setFiles={handleAddAttachments} />
                </div>
                <FileShower
                  files={files}
                  removeFile={handleRemoveAttachment}
                  fileOnClick={() => {}}
                />
              </div>
            </Form>
          </Modal>
          <Modal
            title="Xác nhận"
            centered
            visible={showConfirmModal}
            footer={[
              <Button key="cancel" onClick={handleCloseModal}>
                Hủy
              </Button>,
              <Button
                key="send"
                type="primary"
                onClick={handleCreateViolentStudent}
                // disabled={loading}
              >
                Gửi
              </Button>,
            ]}
          >
            <p>Xác nhận thêm vi phạm? </p>
          </Modal>
        </div>
      </div>
    </div>
  );
}
