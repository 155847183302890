import CusTable from "common/CusTable";
import IsMobile from "hook/isMobile";
import BanTruSaveButton from "page/diemDanh/component/BanTruSaveButton";
import Filter from "page/diemDanh/component/Filter";
import TotalStudents from "page/diemDanh/component/TotalStudents";
import BoardingAttendanceStatus from "page/diemDanh/status/BoardingAttendanceStatus";
import BoardingAttendanceField from "page/diemDanh/tabs/boardingAttendance/column/BoardingAttendanceField";
import React, { memo, useMemo } from "react";
import styles from "../../styles.module.css";

const Inner = memo(
  ({
    totalAttendanceStudents,
    data,
    tableLoading,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    totalItems,
  }) => {
    const isUsingMobile = IsMobile();

    const columns = useMemo(() => {
      let columnLst = [
        {
          title: "STT",
          align: "center",
          key: "STT",
          render: (value, record, index) =>
            (currentPage - 1) * pageSize.value + index + 1,
          width: "5%",
        },
        {
          title: "Họ Tên",
          align: "left",
          key: `students`,
          dataIndex: "full_name",
          render: (text, record) => <div>{text}</div>,
          width: "10%",
          className: styles.fixedCol,
        },
        {
          title: "Điểm danh",
          key: "boarding_attendance",
          align: "center",
          width: "20%",
          render: (value, record) => (
            <BoardingAttendanceField value={value} record={record} />
          ),
        },

        // {
        //   title: "",
        //   dataIndex: "operation",
        //   align: "center",
        //   width: "5%",
        //   render: (_, record) => (
        //     <Button
        //       type="link"
        //       disabled={!isSubmittedAll || !isAllowToUpdate}
        //       onClick={() => edit(record)}
        //     >
        //       <img src={newEditIcon} alt="Edit" />
        //     </Button>
        //   ),
        // },
      ];
      if (isUsingMobile) {
        columnLst = columnLst.filter((item) => item.dataIndex !== "dob");
      }
      return columnLst;
    }, [currentPage, isUsingMobile, pageSize.value]);

    const showBanTruButton = useMemo(() => {
      return true;
    }, []);

    return (
      <div className="px-8">
        <Filter />
        <div
          className={`${
            showBanTruButton ? `${styles.boardingStatus}` : ""
          } mb-5`}
        >
          {showBanTruButton && <BanTruSaveButton />}
          <BoardingAttendanceStatus />
        </div>
        <TotalStudents
          totalAttendanceStudents={totalAttendanceStudents}
          totalStudents={totalItems}
        />

        <CusTable
          rowKey="id"
          data={data}
          columns={columns}
          loading={tableLoading}
          havePagination={totalItems > pageSize.value}
          rowClassName="bg-white attendance-row"
          page={currentPage}
          setPage={setCurrentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalItems={totalItems}
          scroll={{ x: "500px", y: 480 }}
          // scroll={scroll}
        />
      </div>
    );
  }
);

export default Inner;
