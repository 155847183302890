import { Button } from "antd";
import React, { memo } from "react";
import { BIEU_MAU_XIN_PHEP_LINK } from "util/constants";
import styles from "../styles.module.css";

const LeavePermissionButton = memo(() => {
  return (
    <Button
      className={`flex justify-center items-center ${styles.searchBtn}`}
      href={BIEU_MAU_XIN_PHEP_LINK}
      target="blank"
    >
      <span className={`${styles.searchBtnText}`}>Biểu mẫu xin phép</span>
    </Button>
  );
});

export default LeavePermissionButton;
