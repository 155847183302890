import { Button, Spin } from "antd";
import moment from "moment";
import { useAttendanceConext } from "page/diemDanh/Context";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  createBoardingAttendanceCheck,
  getBoardingAttendanceCheck,
} from "util/APIs";
import styles from "../styles.module.css";

const BanTruSaveButton = memo(() => {
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;
  const { selectedDate, roomId } = useAttendanceConext();
  const [loading, setLoading] = useState(false);
  const [boardingCheck, setBoardingCheck] = useState(false);

  const getBoardingAttendanceChecking = useCallback(
    async (roomId) => {
      const params = {
        "filters[classes.id]": roomId,
        "filters[date][$eq]": moment(selectedDate).format("YYYY-MM-DD"),
      };
      const res = await getBoardingAttendanceCheck(accountId, params);
      if (res?.status === 200 && res?.data?.data.length !== 0) {
        setBoardingCheck(true);
      } else {
        setBoardingCheck(false);
      }
    },
    [accountId, selectedDate]
  );

  const handleBoardingAttendanceCheck = useCallback(async () => {
    setLoading(true);
    const body = {
      data: {
        date: moment(selectedDate).format("YYYY-MM-DD"),
        classes: [roomId],
      },
    };
    try {
      const res = await createBoardingAttendanceCheck(accountId, body);
      if (res.status === 200) {
        getBoardingAttendanceChecking(roomId);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  }, [accountId, getBoardingAttendanceChecking, roomId, selectedDate]);

  useEffect(() => {
    if (roomId) {
      getBoardingAttendanceChecking(roomId);
    }
  }, [getBoardingAttendanceChecking, roomId]);

  return (
    <>
      <Spin spinning={loading} tip="Đang lưu bán trú">
        <Button
          className={`flex justify-center ${styles.searchBtn}
            ${boardingCheck ? styles.disableBtn : ""}`}
          disabled={boardingCheck ? true : false}
          onClick={loading ? null : handleBoardingAttendanceCheck}
        >
          <span className={`${styles.searchBtnText}`}>Lưu bán trú</span>
        </Button>
      </Spin>
    </>
  );
});

export default BanTruSaveButton;
