import { Select } from "antd";
import { arrowDownSidebar } from "asset/img/Images";
import React from "react";

export default function SelectOption({
  data,
  value,
  setValue = (_value) => {},
  placeholder = "",
  valueFieldName = "id",
  valueTextName = "name",
  disabled = false,
  className = "",
  optionClassName = "",
  mode = undefined,
  tagRender = undefined,
  showSearch = true,
  allowClear = true,
  ...props
}) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const { Option } = Select;
  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  const handleChange = (data) => {
    setValue(data);
  };
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////
  const renderOption = (item) => {
    let itemValue = null;
    if (valueFieldName) itemValue = item[valueFieldName];
    else itemValue = item.short_id;
    return (
      <Option
        value={itemValue}
        key={itemValue}
        className={`overflow-ellipsis ${optionClassName}`}
      >
        {item[valueTextName]}
      </Option>
    );
  };
  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <Select
      value={value}
      onChange={handleChange}
      className={`w-full ${className}`}
      placeholder={placeholder}
      disabled={disabled}
      suffixIcon={<img src={arrowDownSidebar} alt="Select" />}
      mode={mode}
      showArrow={true}
      tagRender={tagRender}
      showSearch={showSearch}
      filterOption={(input, option) =>
        option?.children?.toLowerCase?.()?.includes(input?.toLowerCase?.())
      }
      allowClear={allowClear}
      {...props}
    >
      {data && data?.map?.(renderOption)}
    </Select>
  );
}
