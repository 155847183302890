import {
  anualLeaveActive,
  lateActive,
  noAuthLeaveActive,
  presentActive,
} from "asset/img/Images";
import React, { memo } from "react";
import styles from "../styles.module.css";

const AttendanceStatus = memo(() => {
  return (
    <div className={`${styles.wrapperAttendanceStatus}`}>
      <div className="flex items-center">
        <img
          src={presentActive}
          alt="Late"
          className={`${styles.statusImage}`}
        />
        <span className={`ml-1 font-semibold ${styles.statusOff}`}>Có mặt</span>
      </div>
      <div className="flex items-center">
        <img src={lateActive} alt="Late" className={`${styles.statusImage}`} />
        <span className={`ml-1 font-semibold ${styles.statusOff}`}>Trễ</span>
      </div>
      <div className="flex items-center">
        <img
          src={anualLeaveActive}
          alt="Anual Leave"
          className={`${styles.statusImage}`}
        />
        <span className={`ml-1 font-semibold ${styles.statusOff}`}>
          Nghỉ có phép
        </span>
      </div>
      <div className="flex items-center">
        <img
          src={noAuthLeaveActive}
          alt="No Auth Leave"
          className={`${styles.statusImage}`}
        />
        <span className={`ml-1 font-semibold ${styles.statusOff}`}>
          Nghỉ không phép
        </span>
      </div>
    </div>
  );
});

export default AttendanceStatus;
