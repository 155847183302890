import { Button } from "antd";
import ExportAttendanceModal from "page/diemDanh/modal/ExportAttendanceModal";
import React, { memo, useState } from "react";
import styles from "../styles.module.css";

const ExportAttendanceButton = memo(() => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Button
        onClick={handleOpenModal}
        className={`flex justify-center items-center ${styles.searchBtn}`}
      >
        <span className={`${styles.searchBtnText}`}>
          Xuất báo cáo chuyên cần
        </span>
      </Button>
      <ExportAttendanceModal
        isModalOpen={isOpen}
        onCloseModal={handleOpenModal}
      />
    </>
  );
});

export default ExportAttendanceButton;
