import { Button, Form, Input, Modal } from "antd";
import InfoFieldSelect from "common/CusInfoField/infoFieldSelect/InfoFieldSelect";
import { Notification } from "common/Notification";
import moment from "moment";
import { useAttendanceConext } from "page/diemDanh/Context";
import React, { memo, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { createAttendanceOneStudent } from "util/APIs";

const AttendanceModal = memo(({ data }) => {
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;
  const { selectedDate, classId, setIsRefresh } = useAttendanceConext();
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => {
    setIsOpen(!isOpen);
  };

  const handleFinish = async (values) => {
    const payload = {
      data: {
        students: values.students,
        // note: values.note,
        date: moment(selectedDate).format("YYYY-MM-DD"),
        workflow_state: "present",
        has_lunch: true,
        lunch_attendance: true,
        // classes: [classId],
        type: "class",
      },
    };
    try {
      const res = await createAttendanceOneStudent(accountId, payload);
      if (res?.status === 200) {
        handleOpenModal();
        Notification.sendSuccess("Điểm danh ăn trưa thành công");
        setIsRefresh(true);
        return;
      }
    } catch (err) {}
  };

  const onFinishFailed = (errorInfo) => {
    console.error("Failed:", errorInfo);
  };

  const filterData = useMemo(() => {
    if (isOpen) {
      // giám thị chi điểm danh ăn trưa cho ~ học sinh ko đăng ký bán trú
      const initialData = data.filter((item) => !item.ban_tru);
      return initialData;
    }
    return [];
  }, [isOpen, data]);

  return (
    <>
      <Button className="rounded-lg mb-5" onClick={handleOpenModal}>
        Điểm danh
      </Button>
      <Modal
        title="Điểm danh"
        open={isOpen}
        onCancel={handleOpenModal}
        footer={null}
        destroyOnClose={true}
        width={500}
        style={{ fontWeight: "bold" }}
        maskClosable={false}
      >
        <Form
          name="basic"
          labelCol={{
            span: 5,
          }}
          labelAlign="left"
          // initialValues={initialvalues}
          onFinish={handleFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          style={{ marginLeft: "2em", fontWeight: "bold" }}
        >
          <Form.Item
            label="Học sinh"
            name="students"
            rules={[
              {
                required: true,
                message: "Vui lòng chọn học sinh!",
              },
            ]}
          >
            <InfoFieldSelect
              label=""
              data={filterData}
              direction="vertical"
              valueTextName="full_name"
            />
          </Form.Item>
          <Form.Item label="Ghi chú" name="note">
            <Input.TextArea className="rounded-lg h-10" />
          </Form.Item>
          <Form.Item>
            <div key="button-row" className="flex items-center justify-center">
              <Button
                size="large"
                key="cancel"
                className="bg-gray-300 rounded-sm mRight12"
                onClick={handleOpenModal}
              >
                Huỷ bỏ
              </Button>
              <Button
                size="large"
                htmlType="submit"
                className="bg-blue-500 text-white rounded-sm"
              >
                Xác nhận
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
});

export default AttendanceModal;
