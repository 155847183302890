import IconCheck from "asset/icons/IconCheck";
import { reloadIcon, uploadIcon, radioCheckedIcon } from "asset/img/Images";
import { Notification } from "common/Notification";
import Avatar from "common/avatar/Avatar";
import Select from "page/teachingManagement/component/Select";
import React, { useRef, useState } from "react";
import { updateStudentViolateRules } from "util/APIs";
import styles from "../styles.module.css";

function EvaluationField({
  accountId,
  values,
  options,
  disabled,
  record,
  selectedDate,
  setIsRefresh,
}) {
  const [data, setData] = useState(values);
  const [files, setFiles] = useState([]);
  const hiddenFileInput = useRef(null);

  const handleUpload = (e) => {
    setFiles(e.target.files);
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handlechangefile = async (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      setFiles((prevFiles) => [...prevFiles, ...files]);
    }
  };
  const handleChange = async () => {
    if (record?.violate_student?.length !== 0) {
      const item = {
        students: [record.id],
        date: selectedDate,
        rules: data,
      };
      const formData = new FormData();
      formData.append("data", JSON.stringify(item));
      if (files) {
        for (let f of files) {
          formData.append("file", f);
        }
      }
      const res = await updateStudentViolateRules(
        accountId,
        record?.violate_student?.[0]?.id,
        formData
      );
      if (res?.status === 200) {
        Notification.sendSuccess("Đánh giá thành công");
        setFiles([]);
        setIsRefresh?.(true);
      }
    } else {
      Notification.sendWarning("Vui lòng thêm vi phạm trước khi chỉnh sửa");
      setIsRefresh?.(true);
    }
  };

  return (
    <div className={`${styles.wrapper}`}>
      <div className="flex items-center gap-2">
        <Select
          className="w-72"
          value={values}
          mode="multiple"
          options={options}
          disabled={disabled}
          onChange={(v) => setData(v)}
          // maxTagCount="responsive"
        />
        <input
          className="hidden"
          type="file"
          multiple
          onChange={handleUpload}
        />
      </div>
      <div>
        {files.length > 0 && files.map((file) => <div>{file.name}</div>)}
      </div>
      <div>
        {disabled ? (
          <Avatar
            src={files.length > 0 ? reloadIcon : uploadIcon}
            size={34}
            className={styles.disabledChangeAvatar}
            imgClassName="w-6 h-6"
            imgIsRound={false}
          />
        ) : (
          <Avatar
            src={files.length > 0 ? reloadIcon : uploadIcon}
            size={34}
            className={styles.changeAvatarBtn}
            imgClassName="w-6 h-6"
            imgIsRound={false}
            onClick={handleClick}
          />
        )}
        <input
          type="file"
          multiple={true}
          onChange={handlechangefile}
          ref={hiddenFileInput}
          style={{ display: "none" }}
        />

        <div className={`w-6`} onClick={handleChange}>
          <IconCheck
            className={`${disabled ? styles.disabledIcon : styles.checkedIcon}`}
          />
        </div>
      </div>
    </div>
  );
}

export default EvaluationField;
