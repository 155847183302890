import useHandleLogin from "hook/handleLoginSuccess";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getOauthJWT } from "util/APIs";

function CustomRedirect() {
  const history = useHistory();
  const currentPath = history.location;
  const [handleLoginSuccess] = useHandleLogin();

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await getOauthJWT(currentPath.search);
        await handleLoginSuccess(res?.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }
    fetchData();
  }, [currentPath.search, handleLoginSuccess]);

    useEffect(() => {
        async function fetchData() {
            try {
                const res = await getOauthJWT(currentPath.search);
                await handleLoginSuccess(res?.data);
            } catch (error) {
                console.error("Error:", error);
            }
        }
        fetchData();
    }, [currentPath.search, handleLoginSuccess]);

    return <div></div>;
}

export default CustomRedirect;
