import React, { memo } from "react";

const TotalStudents = memo(
  ({ totalAttendanceStudents = 0, totalStudents = 0 }) => {
    return (
      <div className="mb-5">
        <span className="text-[16px] font-medium">
          Sỉ số: {`${totalAttendanceStudents} / ${totalStudents}`}
        </span>
      </div>
    );
  }
);

export default TotalStudents;
