import { Dropdown, Input, Menu } from "antd";
import {
  demoAvatar,
  searchIcon,
  settingIcon
} from "asset/img/Images";
import Img from "common/image/Img";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { logout } from "util/APICaller";
import { routes } from "util/routes";
import NotiButton from "./component/NotiButton/NotiButton";
import styles from "./styles.module.css";

export default function Header() {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const history = useHistory();

  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////
  const items = useMemo(() => {
    const lst = [
      {
        key: "1",
        label: <Link to={routes.CHANGE_PASSWORD}>Đổi mật khẩu</Link>,
        isShow: userInfo?.isLocalLogin,
      },
      {
        label: "Đăng xuất",
        key: "2",
        onClick: () => logout(history),
        isShow: true,
      },
    ].filter((item) => item.isShow);

    return lst;
  }, [history, userInfo?.isLocalLogin]);

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////

  return (
    <div className={`flex justify-between items-center ${styles.root}`}>
      <Input
        className={`styled-input ${styles.searchInput}`}
        prefix={
          <div className={`mRight8`}>
            <Img src={searchIcon} alt="search" />
          </div>
        }
        placeholder="Tìm kiếm"
      />
      <div className={`flex items-center`}>
        <NotiButton />
        <Dropdown
          overlay={<Menu items={items} />}
          trigger={["click"]}
          placement="bottomRight"
        >
          <div className={`${styles.settingBtn}`}>
            <Img src={settingIcon} alt="Setting" />
          </div>
        </Dropdown>
        <div className="flex items-center mLeft16">
          <div className={styles.avatar}>
            {/* <Link to={"/staff-management"}> */}
              <Img
                src={userInfo?.avatar || demoAvatar}
                alt="Avatar"
                className={styles.avatarImg}
              />
            {/* </Link> */}
          </div>
          {/* <div className="mLeft16">
            <img src={chevronDownLightIcon} alt="expand" />
          </div> */}
        </div>
      </div>
    </div>
  );
}
