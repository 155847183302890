import { daySchoolActive, noAuthLeaveActive } from "asset/img/Images";
import React, { memo } from "react";
import styles from "../styles.module.css";

const BoardingAttendanceStatus = memo(() => {
  return (
    <div className={`flex items-center justify-start `}>
      <div className={`flex items-center justify-evenly mr-12`}>
        <img
          src={daySchoolActive}
          alt="Late"
          className={`${styles.statusImage}`}
        />
        <span className={`ml-1 font-semibold ${styles.statusOff}`}>Ngủ</span>
      </div>
      <div className="flex items-center justify-end">
        <img
          src={noAuthLeaveActive}
          alt="No Auth Leave"
          className={`${styles.statusImage}`}
        />
        <span className={`ml-1 font-semibold ${styles.statusOff}`}>
          Không ngủ
        </span>
      </div>
    </div>
  );
});

export default BoardingAttendanceStatus;
