import ExportAttendanceButton from "page/diemDanh/header/ExportAttendanceButton";
import ExportReportButton from "page/diemDanh/header/ExportReportButton";
import LeavePermissionButton from "page/diemDanh/header/LeavePermissionButton";
import SaveAllButton from "page/diemDanh/header/SaveAllButton";
import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { isManageRole } from "util/common";
import { routes } from "util/routes";

const HeaderAttendance = memo(() => {
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const location = useLocation();

  const showSaveAllBtn = useMemo(() => {
    const link = `${location.pathname}${location.search}`;
    if (link === routes.ATTENDANCE || link === routes.ATTENDANCE_BY_BOARDING) {
      return true;
    }
    return false;
  }, [location.pathname, location.search]);

  return (
    <>
      <div className="flex flex-wrap items-center gap-2">
        <ExportReportButton />
        {isManageRole(userInfo?.user?.roles) && <ExportAttendanceButton />}
        <LeavePermissionButton />
        {isManageRole(userInfo?.user?.roles) && showSaveAllBtn && (
          <SaveAllButton />
        )}
      </div>
    </>
  );
});

export default HeaderAttendance;
