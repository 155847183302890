import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";
import InfoFieldPassword from "common/CusInfoField/infoFieldPassword/InfoFieldPassword";
import { Notification } from "common/Notification";
import IsMobile from "hook/isMobile";
import MobileHeader from "mobile/layout/header/MobileHeader";
import { HEADER_TYPE } from "mobile/util/mobileConstant";
import React, { memo, useCallback, useMemo, useState } from "react";
import { userService } from "services/userService";
import styles from "./styles.module.css";

const ChangePassword = memo(() => {
  const isUsingMobile = IsMobile();
  const [loading, setLoading] = useState(false);
  const [currentPassword, setcurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");

  const isSubmit = useMemo(() => {
    let check = true;
    if (
      currentPassword === "" ||
      newPassword === "" ||
      newPasswordConfirmation === ""
    ) {
      check = false;
    } else if (newPassword !== newPasswordConfirmation) {
      check = false;
    } else if (currentPassword === newPassword) {
      check = false;
    }
    return check;
  }, [currentPassword, newPassword, newPasswordConfirmation]);

  const handleCancel = useCallback(() => {
    setcurrentPassword("");
    setNewPassword("");
    setNewPasswordConfirmation("");
  }, []);

  const handleChange = useCallback(async () => {
    const payload = {
      currentPassword,
      password: newPassword,
      passwordConfirmation: newPasswordConfirmation,
    };
    setLoading(true);
    try {
      const response = await userService.changePassword(payload);
      if (response?.status === 200) {
        Notification.sendSuccess("Thay đổi mật khẩu thành công.");
        handleCancel();
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  }, [currentPassword, handleCancel, newPassword, newPasswordConfirmation]);

  return (
    <>
      {isUsingMobile ? (
        <MobileHeader
          type={HEADER_TYPE.middleTitle}
          title="Thay đổi mật khẩu"
          haveNotiButton={false}
        />
      ) : (
        <div className={styles.title}>Thay đổi mật khẩu</div>
      )}
      <form
        onSubmit={handleChange}
        className={`${styles.changePasswordLayout}`}
      >
        <InfoFieldPassword
          label="Nhập mật khẩu hiện tại"
          className="mt-2"
          isRequired={true}
          value={currentPassword}
          setValue={setcurrentPassword}
          placeholder="Mật khẩu"
          direction="vertical"
        />
        <InfoFieldPassword
          label="Nhập mật khẩu mới"
          className="mt-2"
          isRequired={true}
          value={newPassword}
          setValue={setNewPassword}
          placeholder="Mật khẩu"
          direction="vertical"
        />
        <InfoFieldPassword
          label="Nhập lại mật khẩu mới"
          className="mt-2"
          isRequired={true}
          value={newPasswordConfirmation}
          setValue={setNewPasswordConfirmation}
          placeholder="Mật khẩu"
          direction="vertical"
        />
        <div className="flex gap-2 mt-8">
          <Button
            className="rounded-lg"
            onClick={handleCancel}
            disabled={loading}
          >
            Huỷ
          </Button>
          <Button
            className="rounded-lg"
            type="primary"
            onClick={handleChange}
            disabled={loading || !isSubmit}
          >
            {loading && (
              <>
                <FontAwesomeIcon icon={faCircleNotch} spin /> Đang xử lý
              </>
            )}
            {!loading && <span>Xác nhận</span>}
          </Button>
        </div>
      </form>
    </>
  );
});

export default ChangePassword;
